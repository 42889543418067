const AcademicSubjectData = [
    {
        Sl: 1,
        SubjectName: 'ENGLISH',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },

    {
        Sl: 2,
        SubjectName: 'ENGLISH',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 3,
        SubjectName: 'ENGLISH',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 4,
        SubjectName: 'ENGLISH',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 5,
        SubjectName: 'Science',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 6,
        SubjectName: 'Science',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 7,
        SubjectName: 'Science',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 8,
        SubjectName: 'Science',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    },
    {
        Sl: 9,
        SubjectName: 'Science',
        ClassName: 'Six',
        ClassTeacher: 'Summer Simpson',
        SubjectCode: '102',
        SubjectType: 'Theory',
        SubjectAuthor: '',

    }

];

export default AcademicSubjectData;
