import GuardianImg from '../assets/images/avatars/10.png'
const ParentInformationData = [
    {
        id: 1,
        GuardianName: 'Binoya Naik',
        Relation: 'son',
        FatherName: 'Binoya Naik',
        MotherName: 'Recusandae',
        Occupation: 'Bussiness',
        Income: '$12,000',
        Education: 'Diploma Eng.',
        City: 'SWALECLIFFE',
        State: 'CT5 7YX',
        MobileNo: '+14424575757',
        Email: 'parent@ramom.com',
        Address: '	69 Golf Road, SWALECLIFFE CT5 7YX',
        GuardianPicture: <img className=' rounded-3 border border-3' src={GuardianImg} alt="img" />,
    }

];

export default ParentInformationData;
