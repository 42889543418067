import '../../styles/messageStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { Link } from "react-router-dom"
import feesDataRows from '../../data/FeesData';
import BooksIssueData from '../../data/BooksIssueData';
import DocumentsData from '../../data/DocumentsData';
import ParentInformationData from '../../data/ParentInformationData';
import PromotionHistoryData from '../../data/PromotionHistoryData';
import { AcademicDetailData, StudentDetailData } from '../../data/ProfileDetailData';
import bgbannerImg from '../../assets/images/pages/profile-banner.png'
import studentprofile from '../../assets/images/avatars/studentprofile.png'
import LiveClassData from '../../data/LiveClassData';

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'

const mangeTabActive = {
    color: 'white',
    // border: '1px solid #7367f0',
    backgroundColor: '#7367f0',
    borderRadius: '6px',
}

const parseCurrency = (currencyString) => {
    return parseFloat(currencyString.replace('$', '').replace(',', ''));
};

const Message = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }

    const { amountSum, discountSum, fineSum, paidSum, balanceSum } = feesDataRows.reduce((acc, item) => {
        acc.amountSum += parseCurrency(item.Amount);
        acc.discountSum += parseCurrency(item.Discount);
        acc.fineSum += parseCurrency(item.Fine);
        acc.paidSum += parseCurrency(item.paid);
        acc.balanceSum += parseCurrency(item.Balance);
        return acc;
    }, {
        amountSum: 0,
        discountSum: 0,
        fineSum: 0,
        paidSum: 0,
        balanceSum: 0
    });


    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const [inputValue, setInputValue] = useState('');

    // Function to handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const defalutValue = {
        role: '',
        receiver: '',
        subject: '',
        message: '',
        attachmentFile: ''
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        role: yup.string().required('Role Type is Requird!'),
        receiver: yup.string().required('Receiver is Requird!'),
        subject: yup.string().required('Subject is Requird!'),
        message: yup.string().required('Message is Requird!'),
        attachmentFile: yup.string().required('Attachment File is Requird!'),
    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="MessagetabsBox">
                <Tabs value={value} onChange={onHandleChange} className="MessagetabsBox row">
                    <div className="Message-lefttabs">
                        <h5 className="card-header d-flex align-items-center mb-2"> Mailbox Folder</h5>
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='Message-tab2 nav-item' value={0}><i className='manageicon fa-solid fa-envelope' /> Compose</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='Message-tab2 nav-item' value={1}><i className="manageicon ti ti-mail" />
                                Inbox
                                <div className="badge bg-danger rounded-pill ms-auto">0</div>
                            </Tab>
                            <Tab style={value === 2 ? { ...mangeTabActive } : {}} className='Message-tab2 nav-item' value={2}><i className='manageicon ti ti-message-forward' />
                                Sent
                                <div className="badge bg-danger rounded-pill ms-auto">0</div>
                            </Tab>
                            <Tab style={value === 3 ? { ...mangeTabActive } : {}} className='Message-tab2 nav-item' value={3}><i className="manageicon ti ti-bell" /> Important </Tab>
                            <Tab style={value === 4 ? { ...mangeTabActive } : {}} className='Message-tab2 nav-item' value={4}><i className="manageicon ti ti-trash" />Trash</Tab>

                        </TabsList>
                    </div>
                    <div className="Message-TabPanelDiv">
                        <TabPanel value={0}>
                            <div className="card px-3">
                                <div className="card-header">
                                    <h5 className="card-tile mb-0">Write Message</h5>
                                </div>
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div className="col-md-6 w-100">
                                                    <label className="form-label" htmlFor="formValidationSelect2">Role</label>
                                                    <Field component='select' name='role' className={
                                                        `form-control
                                                            ${errors.role && touched.role ? ' is-invalid' : ''}`
                                                    }>
                                                        <option >--Select--</option>
                                                        <option value={71}>Airtel Prepaid</option>
                                                        <option value={82}>BSNL Prepaid</option>
                                                        <option value={83}>Vi Prepaid</option>
                                                        <option value={94}>JIO</option>
                                                    </Field>
                                                    <p className='error_message_p'> <ErrorMessage name="role" /></p>
                                                </div>

                                                <div className="col-md-6 w-100 my-3">
                                                    <label className="form-label" htmlFor="formValidationSelect2">Receiver</label>
                                                    <Field component='select' name='receiver' className={
                                                        `form-control
                                                            ${errors.receiver && touched.receiver ? ' is-invalid' : ''}`
                                                    }>
                                                        <option >--Select--</option>
                                                        <option value={71}>Airtel Prepaid</option>
                                                        <option value={82}>BSNL Prepaid</option>
                                                        <option value={83}>Vi Prepaid</option>
                                                        <option value={94}>JIO</option>
                                                    </Field>
                                                    <p className='error_message_p'> <ErrorMessage name="receiver" /></p>
                                                </div>

                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="ecommerce-product-name">Subject</label>
                                                    <Field type='text'
                                                        name='subject' placeholder='Subject '
                                                        className={
                                                            `form-control
                                                            ${errors.subject && touched.subject ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="subject" /></p>
                                                </div>

                                                {/* Description */}
                                                {/* <div>
                                        <label className="form-label">Description (Optional)</label>
                                        <div className="form-control p-0 pt-1">
                                            <div className="comment-toolbar border-0 border-bottom">
                                                <div className="d-flex justify-content-start">
                                                    <span className="ql-formats me-0">
                                                        <button className="ql-bold" />
                                                        <button className="ql-italic" />
                                                        <button className="ql-underline" />
                                                        <button className="ql-list" value="ordered" />
                                                        <button className="ql-list" value="bullet" />
                                                        <button className="ql-link" />
                                                        <button className="ql-image" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="comment-editor border-0 pb-4" id="ecommerce-category-description" />
                                        </div>
                                    </div> */}

                                                <div className="col-md-6 w-100">
                                                    <label className="form-label" htmlFor="formValidationBio">Message</label>
                                                    <Field
                                                        as="textarea"
                                                        className={
                                                            `form-control
                                                            ${errors.message && touched.message ? ' is-invalid' : ''}`
                                                        }
                                                        id="formValidationBio"
                                                        name="message" rows={3} defaultValue={""}
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="message" /></p>

                                                </div>
                                                <div className="my-3">
                                                    <label htmlFor="formFile" className="form-label">Attachment File</label>
                                                    <Field
                                                        type="file"
                                                        name='attachmentFile' placeholder='Enter Product Name '
                                                        className={
                                                            `form-control
                                                            ${errors.attachmentFile && touched.attachmentFile ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="attachmentFile" /></p>
                                                </div>


                                                <div className="col-12 d-flex justify-content-end py-2">
                                                    <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                        <span className="ti-xs ti ti-x me-1"></span>Discard
                                                    </button>

                                                    <button
                                                        type="submit" name="submitButton" className="btn btn-primary">
                                                        <span class="ti-xs ti ti-brand-telegram me-1"></span>Send
                                                    </button>

                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>

                            <div className="card px-3">
                                <div className="TeachersList d-flex justify-content-between p-1 align-items-center">
                                    <h5 className="card-header d-flex align-items-center "><i className=' me-2 ti ti-mail' />Inbox</h5>
                                    <div>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-outline-dark waves-effect me-2">
                                            <span className="ti ti-refresh fs-5"></span>
                                        </button>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-danger waves-effect me-2">
                                            <span className="ti ti-trash fs-5"></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Sender</TableCell>
                                                    <TableCell component={'th'} className=''>Subjects</TableCell>
                                                    <TableCell component={'th'} className=''>Message</TableCell>
                                                    <TableCell component={'th'} className=''>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {LiveClassData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                                            <TableCell component={'td'}>{item.MeetingID}</TableCell>
                                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={2}>
                            <div className="card px-3">
                                <div className="TeachersList d-flex justify-content-between p-1 align-items-center">
                                    <h5 className="card-header d-flex align-items-center "><i className=' me-2 ti ti-mail' />Sent</h5>
                                    <div>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-outline-dark waves-effect me-2">
                                            <span className="ti ti-refresh fs-5"></span>
                                        </button>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-danger waves-effect me-2">
                                            <span className="ti ti-trash fs-5"></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Receiver</TableCell>
                                                    <TableCell component={'th'} className=''>Subjects</TableCell>
                                                    <TableCell component={'th'} className=''>Message</TableCell>
                                                    <TableCell component={'th'} className=''>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {LiveClassData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                                            <TableCell component={'td'}>{item.MeetingID}</TableCell>
                                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={3}>
                            <div className="card px-3">
                                <div className="TeachersList d-flex justify-content-between p-1 align-items-center">
                                    <h5 className="card-header d-flex align-items-center "><i className=' me-2 ti ti-bell' />Important</h5>
                                    <div>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-outline-dark waves-effect me-2">
                                            <span className="ti ti-refresh fs-5"></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Type</TableCell>
                                                    <TableCell component={'th'} className=''>Sender / Receiver</TableCell>
                                                    <TableCell component={'th'} className=''>Subjects</TableCell>
                                                    <TableCell component={'th'} className=''>Message</TableCell>
                                                    <TableCell component={'th'} className=''>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {LiveClassData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                                            <TableCell component={'td'}>{item.MeetingID}</TableCell>
                                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={4}>
                            <div className="card px-3">
                                <div className="TeachersList d-flex justify-content-between p-1 align-items-center">
                                    <h5 className="card-header d-flex align-items-center "><i className=' me-2 ti ti-trash' />Trash</h5>
                                    <div>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-outline-dark waves-effect me-2">
                                            <span className="ti ti-refresh fs-5"></span>
                                        </button>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-outline-dark waves-effect me-2">
                                            <span className="ti ti-arrow-back-up fs-5"></span>
                                        </button>
                                        <button type="button" className="btn btn-sm rounded-pill btn-icon btn-danger waves-effect me-2">
                                            <span className="ti ti-trash fs-5"></span>
                                        </button>
                                    </div>
                                </div>
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Receiver</TableCell>
                                                    <TableCell component={'th'} className=''>Subjects</TableCell>
                                                    <TableCell component={'th'} className=''>Message</TableCell>
                                                    <TableCell component={'th'} className=''>Time</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {LiveClassData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                                            <TableCell component={'td'}>{item.MeetingID}</TableCell>
                                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default Message
