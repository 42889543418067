import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import BookListData from '../../data/BookListData';

const BooksList = () => {
    return (
        <>



            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className=' me-2 fa-solid fa-list' /> Books List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Book Title</TableCell>
                                    <TableCell component={'th'} className=''>Cover</TableCell>
                                    <TableCell component={'th'} className=''>Edition</TableCell>
                                    <TableCell component={'th'} className=''>ISBN No</TableCell>
                                    <TableCell component={'th'} className=''>Category</TableCell>
                                    <TableCell component={'th'} className=''>Description</TableCell>
                                    <TableCell component={'th'} className=''>Purchase Date</TableCell>
                                    <TableCell component={'th'} className=''>Price</TableCell>
                                    <TableCell component={'th'} className=''>Total Stock</TableCell>
                                    <TableCell component={'th'} className=''>Issued Copies</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {BookListData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.BookTitle}</TableCell>
                                            <TableCell component={'td'}>
                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                    <li
                                                        data-bs-toggle="tooltip"
                                                        data-popup="tooltip-custom"
                                                        data-bs-placement="top"
                                                        class="avatar avatar-md pull-up"
                                                        title="Lilian Fuller">
                                                        <img src={item.Cover} alt="Avatar" class="rounded-circle" />
                                                    </li>
                                                </ul>
                                            </TableCell>
                                            <TableCell component={'td'}>{item.Edition}</TableCell>
                                            <TableCell component={'td'}>{item.ISBNNo}</TableCell>
                                            <TableCell component={'td'}>{item.Category}</TableCell>
                                            <TableCell component={'td'}>{item.Description}</TableCell>
                                            <TableCell component={'td'}>{item.PurchaseDate}</TableCell>
                                            <TableCell component={'td'}>{item.Price}</TableCell>
                                            <TableCell component={'td'}>{item.TotalStock}</TableCell>
                                            <TableCell component={'td'}>{item.IssuedCopies}</TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BooksList
