

const EventData = [
    {
        Sl: 1,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },


    {
        Sl: 2,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 3,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 4,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 5,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 6,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 7,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 8,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    },
    {
        Sl: 9,
        Title: 'Summer vacation',
        Type: '	vacation',
        DateOfStart: '	Summer',
        DateOfEnd: '	Summeation',
        Audience: '	Sumion',
        CreatedBy: 'ffffff'

    }

];

export default EventData;
