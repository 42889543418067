const PromotionHistoryData = [
    {
        id: 1,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 2,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 3,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 4,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 5,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 6,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 7,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 8,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    },
    {
        id: 9,
        classname: 'Six (A)',
        fromsession: '2024-2025',
        promotedclass: 'Seven (A)',
        promotedsession: '2024-2025',
        dueamount: '$2316.00',
        promoteddate:'21.Aug.2022',
    }

];

export default PromotionHistoryData;
