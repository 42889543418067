
export const SupervisionHotelData = [
    {
        id: 1,
        HostelName: 'Danielle',
        HostelCategory: 'Solomon',
        WatchmanName: 'Malexds',
        HostelAddress: 'Osdddff',
        RoomName: '200829',
        RoomCategory: 'Armenian',
        NoOfBeds: '2',
        CostPerBed: '$14.02',
    }
];

