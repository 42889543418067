import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { Link } from "react-router-dom"
import OnlineExamData from '../../data/OnlineExamData';
import { SupervisionHotelData } from '../../data/SupervisionHotelData';



const mangeTabActive = {
  color: '#7367f0',
  borderBottom: '2px solid #7367f0',
  // backgroundColor: '#7367f0',
  borderRadius: '0',
}

const SupervisionTransport = () => {

  const [value, setValue] = useState(0);

  const onHandleChange = (event, newValue) => {
    setValue(newValue)
  }


  return (
    <>

      <div className="tabsBox">
        <Tabs value={value} onChange={onHandleChange} className="tabs">
          <div className="left-tabs">
            <TabsList className='mb-3'>
              <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-bus' />  Assigned</Tab>
              <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-edit" /> Route List</Tab>
            </TabsList>
          </div>
          <div className="TabPanelDiv">
            <TabPanel value={0}>

              <div class="card p-3">
                <div className="card-body  p-0">
                  <div className="table-responsive text-nowrap">
                    <Table className="table table-bordered">
                      <TableBody component={'tbody'}>
                        {SupervisionHotelData.map((item) => (
                          <>
                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Route Name</TableCell>
                              <TableCell align='right' component={'td'}>{item.HostelName}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Start Place</TableCell>
                              <TableCell align='right' component={'td'}>{item.HostelCategory}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Stop Place</TableCell>
                              <TableCell align='right' component={'td'}>{item.WatchmanName}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Stoppage</TableCell>
                              <TableCell align='right' component={'td'}>{item.HostelAddress}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Stop Time</TableCell>
                              <TableCell align='right' component={'td'}>{item.RoomName}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Route Fare</TableCell>
                              <TableCell align='right' component={'td'}>{item.RoomCategory}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Vehicle No</TableCell>
                              <TableCell align='right' component={'td'}>{item.NoOfBeds}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Driver Name</TableCell>
                              <TableCell align='right' component={'td'}>{item.CostPerBed}</TableCell>
                            </TableRow>

                            <TableRow component={'tr'}>
                              <TableCell align='left' component={'td'}>Driver Phone</TableCell>
                              <TableCell align='right' component={'td'}>{item.CostPerBed}</TableCell>
                            </TableRow>

                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>

            </TabPanel>

            <TabPanel value={1}>
              <div class="card p-3">
                <div className="exportFile">
                  <div className="fileDownload">
                    <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-copy" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-file-text" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-file-code-2" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-printer" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                    </a>

                  </div>

                  <div>
                    <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                  </div>
                </div>

                <div className="card-body  px-0">
                  <div className="table-responsive text-nowrap">
                    <Table className="table table-bordered">
                      <TableHead component={'thead'} className='table-light'>
                        <TableRow component={'tr'}>
                          <TableCell component={'th'} className=''>Sl</TableCell>
                          <TableCell component={'th'} className=''>Route Name</TableCell>
                          <TableCell component={'th'} className=''>Start Place</TableCell>
                          <TableCell component={'th'} className=''>Stoppage</TableCell>
                          <TableCell component={'th'} className=''>Stop Place</TableCell>
                          <TableCell component={'th'} className=''>Route Fare</TableCell>
                          <TableCell component={'th'} className=''>Vehicle No</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody component={'tbody'}>

                        {OnlineExamData.map((item) => (
                          <>
                            <TableRow component={'tr'}>
                              <TableCell component={'td'}>{item.Sl}</TableCell>
                              <TableCell component={'td'}>{item.Title}</TableCell>
                              <TableCell component={'td'}>{item.ClassSection}</TableCell>
                              <TableCell component={'td'}>{item.Subject}</TableCell>
                              <TableCell component={'td'}>{item.QuestionsQty}</TableCell>
                              <TableCell component={'td'}>{item.StartTime}</TableCell>
                              <TableCell component={'td'}>{item.StartTime}</TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>

            </TabPanel>
          </div>
        </Tabs>
      </div>


    </>
  )
}

export default SupervisionTransport

