

export const marks = [
    {
        id: 1,
        Subjects: 'Einglish',
        Practical: '20/40',
        Written: '30/60',
        Total: '50/100'

    },

    {
        id: 2,
        Subjects: 'Einglish',
        Practical: '20/40',
        Written: '30/60',
        Total: '50/100'
    },
    {
        id: 3,
        Subjects: 'Einglish',
        Practical: '20/40',
        Written: '30/60',
        Total: '50/100'
    },
    {
        id: 4,
        Subjects: 'Einglish',
        Practical: '20/40',
        Written: '30/60',
        Total: '50/100'
    },
    {
        id: 5,
        Subjects: 'Einglish',
        Practical: '20/40',
        Written: '30/60',
        Total: '50/100'
    }

];

export const marksAndGrade = [
    {
        id: 1,
        Subjects: 'Einglish',
        Practical: '16/20',
        Attendance:'12/20',
        Written: '40/50',
        Viva:'5/10',
        Total: '73/100',
        Grade:'A',
        Point:'4.00'

    },

    {
        id: 2,
        Subjects: 'Einglish',
        Practical: '16/20',
        Attendance:'12/20',
        Written: '40/50',
        Viva:'5/10',
        Total: '73/100',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 3,
        Subjects: 'Einglish',
        Practical: '16/20',
        Attendance:'12/20',
        Written: '40/50',
        Viva:'5/10',
        Total: '73/100',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 4,
        Subjects: 'Einglish',
        Practical: '16/20',
        Attendance:'12/20',
        Written: '40/50',
        Viva:'5/10',
        Total: '73/100',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 5,
        Subjects: 'Einglish',
        Practical: '16/20',
        Attendance:'12/20',
        Written: '40/50',
        Viva:'5/10',
        Total: '73/100',
        Grade:'A',
        Point:'4.00'
    }

];


export const GPA = [
    {
        id: 1,
        Subjects: 'Einglish',
        Practical: 'A',
        Written: 'A+',
        Grade:'A',
        Point:'4.00'

    },

    {
        id: 2,
        Subjects: 'Einglish',
        Practical: 'A',
        Written: 'A+',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 3,
        Subjects: 'Einglish',
        Practical: 'A',
        Written: 'A+',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 4,
        Subjects: 'Einglish',
        Practical: 'D',
        Written: 'D',
        Grade:'A',
        Point:'4.00'
    },
    {
        id: 5,
        Subjects: 'Einglish',
        Practical: 'D',
        Written: 'D',
        Grade:'A',
        Point:'4.00'
    }

];



export const result = [
    {
        GRANDTOTAL: '416 / 500',
        Average: '83.20%',
        GRANDTOTALINWORDS: 'Four Hundred Sixteen',
        GPA: 4.80,
        RESULT: 'Pass',
        Position: 3,
        PrincipalComments: 'Excellent',
        TeacherComments: 'Good'
    }
]
