import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { AcademicClassScheduleData } from "../../data/AcademicClassScheduleData";
import '../../styles/academicClassScheduleStyle.css'

const AcademicClassSchedule = () => {
    return (
        <>
            <div class="card p-3">
                <div className="TeachersList d-flex justify-content-between p-1 align-items-center">
                    <h5 className="card-header d-flex align-items-center "><i className=' me-2 fa-solid fa-user-clock' />Schedule List</h5>
                    <button type="button" class="btn btn-sm rounded-pill btn-icon btn-outline-secondary waves-effect me-2">
                        <span class="ti ti-printer fs-5"></span>
                    </button>
                </div>
                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableBody component={'tbody'}>

                                {AcademicClassScheduleData.map((item, index) => (
                                    <>
                                        <TableRow key={item.id} component={'tr'}>
                                            <TableCell component={'td'}>
                                                <div>
                                                    <h6>{item.day}</h6>
                                                </div>
                                            </TableCell>
                                            <TableCell component={'td'}>
                                                <div className="timetableDiv">
                                                    <h6>{item.subject}</h6>
                                                    <p>{item.time}</p>
                                                    <p>Teacher : {item.Teacher}</p>
                                                </div>
                                            </TableCell>

                                            <TableCell component={'td'}>
                                                <div className="timetableDiv">
                                                    <h6>{item.subject}</h6>
                                                    <p>{item.time}</p>
                                                    <p>Teacher : {item.Teacher}</p>
                                                </div>
                                            </TableCell>


                                            <TableCell component={'td'}>
                                                <div className="timetableDiv">
                                                    <h6>{item.subject}</h6>
                                                    <p>{item.time}</p>
                                                    <p>Teacher : {item.Teacher}</p>
                                                </div>
                                            </TableCell>


                                            <TableCell component={'td'}>
                                                <div className="timetableDiv">
                                                    <h6>{item.subject}</h6>
                                                    <p>{item.time}</p>
                                                    <p>Teacher : {item.Teacher}</p>
                                                </div>
                                            </TableCell>


                                            <TableCell component={'td'}>
                                                <div className="timetableDiv">
                                                    <h6>{item.subject}</h6>
                                                    <p>{item.time}</p>
                                                    <p>Teacher : {item.Teacher}</p>
                                                </div>
                                            </TableCell>


                                        </TableRow>
                                    </>
                                ))}

                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcademicClassSchedule
