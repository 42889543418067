export const  AcademicDetailData = [
    {
        id: 1,
        Branch: 'Icon School & College',
        AcademicYear: '2024-2025',
        RegisterNo: 'RSM-00001',
        Roll: '1',
        AdmissionDate: '2024-03-25',
        Class: 'Six',
        Section: 'A',
        Category: 'General',
    }
];

export const  StudentDetailData = [
    {
        id: 1,
        FirstName: 'Danielle',
        LastName: 'Solomon',
        Gender: 'Male',
        Bloodgroup: 'O+',
        DateOfBirth: '2008-12-29',
        MotherTongue: 'Armenian',
        Religion: 'Christian',
        Caste: 'Christiana',
        MobileNo: '+14424575757',
        Email: 'parent@ramom.com',
        City: 'Portsmouth',
        State: 'Virginia',
        PresentAddress: '	69 Golf Road, SWALECLIFFE CT5 7YX',
        PermanentAddress: '	69 Golf Road, SWALECLIFFE CT5 7YX',
    }
];

