export const  AcademicClassScheduleData = [
    {
        id: 1,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    },
    {
        id: 2,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    },{
        id: 3,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    },
    {
        id: 4,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    },
    {
        id: 5,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    },
    {
        id: 6,
        day:'SUNDAY',
        subject: 'Einglish',
        time: '(10:00 AM - 10:30 AM)',
        Teacher: 'Summer Simpson',
    }

];