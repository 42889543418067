import img1 from '../assets/images/avatars/12.png'
const TeachersData = [
    {
        Sl: 1,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    							
    {
        Sl: 2,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 3,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 4,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 5,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 6,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 7,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 8,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    },
    {
        Sl: 9,
        Photo: img1,
        Name: 'Summer Simpson',
        StaffId: 'f528d10',
        Designation: 'Teacher',
        Department: 'Science',
        Email:'teacher@ramom.com',
        MobileNo:'+1 979-776-5395'
    }

];

export default TeachersData;
