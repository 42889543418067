import '../../styles/feesHistoryStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { Link } from "react-router-dom"
import OnlineExamData from '../../data/OnlineExamData';
import LeaveApplicationData from '../../data/LeaveApplicationData';
import img1 from '../../assets/images/printing-logo.png'
import { FeesHistoryData } from '../../data/FeesHistoryData';
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'


const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const FeesHistory = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }

    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const defalutValue = {
        feesType: '',
        amount: '',
        fine: '',
        paymentMethod: '',
        dateOfPayment: '',
        reference: '',
        note: ''
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        feesType: yup.string().required('Fees Type is Requird!'),
        amount: yup.string().required('Amount is Requird!'),
        fine: yup.string().required('Fine is Requird!'),
        paymentMethod: yup.string().required('Payment Method is Requird!'),
        dateOfPayment: yup.date().required('Date Of Payment is Requird!'),
        reference: yup.string().required('Reference is Requird!'),
        note: yup.string().required('note is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-credit-card' />Invoice</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon ti ti-currency-dollar" />  Payment History</Tab>
                            <Tab style={value === 2 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={2}><i class="manageicon  ti ti-credit-card" />  Online Pay</Tab>
                            <Tab style={value === 3 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={3}><i class="manageicon  ti ti-credit-card" />  Offline Payments</Tab>
                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>

                            <div class="card p-3">
                                <div className=' dflexbetween'>
                                    <div>
                                        <img src={img1} alt="img" />
                                    </div>
                                    <div className='divdetail'>
                                        <h6>Invoice No #0001</h6>
                                        <p>Date : 30.Apr.2024</p>
                                        <p>Status : <span class="badge bg-label-success me-1">Partly Paid</span> </p>
                                    </div>
                                </div>
                                <div className=' dflexbetween'>
                                    <div>
                                        <h6>Invoice To :</h6>
                                        <p>Danielle Solomon</p>
                                        <p>D/2684 Jefferson Street, Portsmouth, Virginia.</p>
                                        <p>Class : Six</p>
                                        <p>Email : student@ramom.com</p>
                                    </div>
                                    <div className='divdetail'>
                                        <h6>Academic :</h6>
                                        <p>Icon School & College</p>
                                        <p>Chyakunjo, Moylapotha, New York.</p>
                                        <p>+158 2045 0412</p>
                                        <p>icon@gmail.com</p>
                                    </div>
                                </div>
                                <div className="card-body  p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>#</TableCell>
                                                    <TableCell component={'th'} className=''>Fees Type</TableCell>
                                                    <TableCell component={'th'} className=''>Due Date</TableCell>
                                                    <TableCell component={'th'} className=''>Status</TableCell>
                                                    <TableCell component={'th'} className=''>Amount</TableCell>
                                                    <TableCell component={'th'} className=''>Discount</TableCell>
                                                    <TableCell component={'th'} className=''>Fine</TableCell>
                                                    <TableCell component={'th'} className=''>Paid</TableCell>
                                                    <TableCell component={'th'} className=''>Balance</TableCell>
                                                </TableRow>
                                                <TableRow component={'tr'}>
                                                    <TableCell colSpan={9} component={'th'} className='fw-bold'>Class One Half Yearly <i className='ti ti-corner-right-down ti-lg'></i></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>
                                                {FeesHistoryData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.FeesType}</TableCell>
                                                            <TableCell component={'td'}>{item.DueDate}</TableCell>
                                                            <TableCell component={'td'}><span class="badge bg-label-success me-1">Total Paid</span></TableCell>
                                                            <TableCell component={'td'}>{item.Amount}</TableCell>
                                                            <TableCell component={'td'}>{item.Discount}</TableCell>
                                                            <TableCell component={'td'}>{item.Fine}</TableCell>
                                                            <TableCell component={'td'}>{item.Paid}</TableCell>
                                                            <TableCell component={'td'}>{item.Balance}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                                <TableRow component={'tr'} className=' bg-light'>
                                                    <TableCell colSpan={9} component={'th'} className='fw-bold'>ClassOneHalfYearly <i className='ti ti-corner-right-down ti-lg'></i></TableCell>
                                                </TableRow>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'td'}>1</TableCell>
                                                    <TableCell component={'td'}>Exam Fees</TableCell>
                                                    <TableCell component={'td'}>03.Mar.2024</TableCell>
                                                    <TableCell component={'td'}><span class="badge bg-label-danger me-1">Unpaid</span></TableCell>
                                                    <TableCell component={'td'}>$150.00</TableCell>
                                                    <TableCell component={'td'}>$0.00</TableCell>
                                                    <TableCell component={'td'}>$0.00</TableCell>
                                                    <TableCell component={'td'}>$0.00</TableCell>
                                                    <TableCell component={'td'}>$150.00</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>

                                        <div className='main-totalgrand'>
                                            <div className='totalgrand'>
                                                <div>
                                                    <p>Grand Total : $1350.00</p>
                                                </div>
                                                <div>
                                                    <p>Paid : $300.00</p>
                                                </div>
                                                <div>
                                                    <p>Discount : $0.00</p>
                                                </div>
                                                <div>
                                                    <p>Fine : $0.00</p>
                                                </div>
                                                <div>
                                                    <p>Balance : $1050.00 <br /> ( One Thousand Fifty )</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-end py-2">
                                            <button type="submit" name="submitButton" className="btn btn-primary me-1">
                                                <span class="ti-xs ti ti-printer me-1"></span>print
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                            <div class="card p-3">
                                <div className=' dflexbetween'>
                                    <div>
                                        <img src={img1} alt="img" />
                                    </div>
                                    <div className='divdetail'>
                                        <h6>Invoice No #0001</h6>
                                        <p>Date : 30.Apr.2024</p>
                                        <p>Status : <span class="badge bg-label-success me-1">Partly Paid</span> </p>
                                    </div>
                                </div>
                                <div className=' dflexbetween'>
                                    <div>
                                        <h6>Invoice To :</h6>
                                        <p>Danielle Solomon</p>
                                        <p>D/2684 Jefferson Street, Portsmouth, Virginia.</p>
                                        <p>Class : Six</p>
                                        <p>Email : student@ramom.com</p>
                                    </div>
                                    <div className='divdetail'>
                                        <h6>Academic :</h6>
                                        <p>Icon School & College</p>
                                        <p>Chyakunjo, Moylapotha, New York.</p>
                                        <p>+158 2045 0412</p>
                                        <p>icon@gmail.com</p>
                                    </div>
                                </div>
                                <div className="card-body  p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>#</TableCell>
                                                    <TableCell component={'th'} className=''>Fees Type</TableCell>
                                                    <TableCell component={'th'} className=''>Fees Code</TableCell>
                                                    <TableCell component={'th'} className=''>Date</TableCell>
                                                    <TableCell component={'th'} className=''>Collect By</TableCell>
                                                    <TableCell component={'th'} className=''>Remarks</TableCell>
                                                    <TableCell component={'th'} className=''>Method</TableCell>
                                                    <TableCell component={'th'} className=''>Amount</TableCell>
                                                    <TableCell component={'th'} className=''>Discount</TableCell>
                                                    <TableCell component={'th'} className=''>Fine</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>
                                                {FeesHistoryData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.FeesType}</TableCell>
                                                            <TableCell component={'td'}>{item.DueDate}</TableCell>
                                                            <TableCell component={'td'}>{item.Amount}</TableCell>
                                                            <TableCell component={'td'}>{item.Amount}</TableCell>
                                                            <TableCell component={'td'}>{item.Amount}</TableCell>
                                                            <TableCell component={'td'}>{item.Discount}</TableCell>
                                                            <TableCell component={'td'}>{item.Fine}</TableCell>
                                                            <TableCell component={'td'}>{item.Paid}</TableCell>
                                                            <TableCell component={'td'}>{item.Balance}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>

                                        <div className='main-totalgrand'>
                                            <div className='totalgrand'>
                                                <div>
                                                    <p>Grand Total : $1350.00</p>
                                                </div>
                                                <div>
                                                    <p>Paid : $300.00</p>
                                                </div>
                                                <div>
                                                    <p>Discount : $0.00</p>
                                                </div>
                                                <div>
                                                    <p>Fine : $0.00</p>
                                                </div>
                                                <div>
                                                    <p>Balance : $1050.00 <br /> ( One Thousand Fifty )</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-end py-2">
                                            <button type="submit" name="submitButton" className="btn btn-primary me-1">
                                                <span class="ti-xs ti ti-printer me-1"></span>print
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={2}>
                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="row g-3 w-50 m-auto">

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Fees Type</label>
                                                        <Field component='select' name='feesType' className={
                                                            `form-control
                                                            ${errors.feesType && touched.feesType ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="feesType" /></p>
                                                    </div>


                                                    <div className="col-md-6 w-100">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">Amount</label>
                                                        <Field type='text'
                                                            name='amount' placeholder='Amount '
                                                            className={
                                                                `form-control
                                                                     ${errors.amount && touched.amount ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="amount" /></p>

                                                    </div>


                                                    <div className="col-md-6 w-100">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">Fine</label>
                                                        <Field type='text'
                                                            name='fine' placeholder='Fine '
                                                            className={
                                                                `form-control
                                                                     ${errors.fine && touched.fine ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="fine" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Payment Method</label>
                                                        <Field component='select' name='paymentMethod' className={
                                                            `form-control
                                                            ${errors.paymentMethod && touched.paymentMethod ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="paymentMethod" /></p>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-credit-card me-1"></span>Fees Pay Now
                                                        </button>
                                                        <button type="button" className="btn btn-danger ms-2" onClick={resetForm}>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>
                        </TabPanel>

                        <TabPanel value={3}>
                            <div className="card p-3">
                                <div className='TeachersList'>
                                    <h5 className="card-header d-flex align-items-center"><i className='me-2 ti ti-credit-card' /> Payment</h5>
                                </div>

                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div className="row g-3 w-50 m-auto">

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Fees Type</label>
                                                        <Field component='select' name='feesType' className={
                                                            `form-control
                                                            ${errors.feesType && touched.feesType ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="feesType" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Payment Method</label>
                                                        <Field component='select' name='paymentMethod' className={
                                                            `form-control
                                                            ${errors.paymentMethod && touched.paymentMethod ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="paymentMethod" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationName">Date Of Payment</label>
                                                        <Field type='datetime-local' name='dateOfPayment' placeholder='Date Of Payment '
                                                            className={
                                                                `form-control
                                                            ${errors.dateOfPayment && touched.dateOfPayment ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="dateOfPayment" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">Amount</label>
                                                        <Field type='text'
                                                            name='amount' placeholder='Amount '
                                                            className={
                                                                `form-control
                                                                     ${errors.amount && touched.amount ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="amount" /></p>
                                                    </div>


                                                    <div className="col-md-6 w-100">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">Fine</label>
                                                        <Field type='text'
                                                            name='fine' placeholder='Fine '
                                                            className={
                                                                `form-control
                                                                     ${errors.fine && touched.fine ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="fine" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">Reference</label>
                                                        <Field type='text'
                                                            name='reference' placeholder='Reference '
                                                            className={
                                                                `form-control
                                                                     ${errors.reference && touched.reference ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="reference" /></p>
                                                    </div>


                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationBio">Note</label>
                                                        <Field
                                                        as="textarea"
                                                            className={
                                                                `form-control
                                                                 ${errors.note && touched.note ? ' is-invalid' : ''}`
                                                            }
                                                            id="formValidationBio" name="note"
                                                            rows={3} defaultValue={""}
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="note" /></p>

                                                    </div>



                                                    {/* Profile Picture  */}
                                                    <div className="col-12">
                                                        <div className="card mb-4">
                                                            <h5 className="card-header">Proof Of Payment</h5>
                                                            <div className="card-body p-0">
                                                                <form onClick={handleFormClick} action="" className="dropzone needsclick dz-clickable" id="dropzone-basic">
                                                                    <div className="dz-message needsclick fs-6">
                                                                        Drop files here or click to upload
                                                                        {/* <span className="note needsclick">(This is just a demo dropzone. Selected files are
                                                                    <span className="fw-medium">not</span> actually uploaded.)</span> */}
                                                                    </div>
                                                                </form>
                                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-credit-card me-1"></span>Pay
                                                        </button>
                                                        <button type="button" className="btn btn-danger ms-2" onClick={resetForm}>
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                            <div className="card mt-3">
                                <div className='TeachersList'>
                                    <h5 className="card-header d-flex align-items-center"><i className='me-2 ti ti-credit-card' /> Offline Payments</h5>
                                </div>
                                <div className="exportFile m-1">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>
                                <div className="card-datatable table-responsive">
                                    <div id="DataTables_Table_3_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">

                                        <Table component={'table'} className="dt-multilingual table dataTable no-footer dtr-column" id="DataTables_Table_3" aria-describedby="DataTables_Table_3_info">
                                            <TableHead component={'thead'} className=' table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label='Trx Id: activate to sort column ascending'>Trx Id</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Student: activate to sort column ascending">Student</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Payment Date: activate to sort column ascending">Payment Date</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Submit Date: activate to sort column ascending">Submit Date</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Amount: activate to sort column ascending">Amount</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Fine: activate to sort column ascending">Fine</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Status: activate to sort column ascending">Status</TableCell>
                                                    <TableCell component={'th'} className="sorting" tabIndex={0} aria-controls="DataTables_Table_3" aria-label="Action: activate to sort column ascending">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>
                                                {LeaveApplicationData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'} className="odd">
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">{item.Title}</TableCell>
                                                            <TableCell component={'td'} valign="top" className="dataTables_empty"><span class="badge bg-label-success me-1">Approved</span></TableCell>
                                                            <TableCell component={'th'} valign="top" className="dataTables_empty">
                                                                <div className=' d-flex align-items-center'>
                                                                    <button type="button" class="btn rounded-pill btn-icon btn-outline-dark waves-effect me-1 btn-sm">
                                                                        <span class="ti ti-menu-2 ti-xs"></span>
                                                                    </button>
                                                                </div>

                                                            </TableCell>

                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_info" id="DataTables_Table_3_info" role="status" aria-live="polite"></div>
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_3_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button page-item previous disabled" id="DataTables_Table_3_previous"><a aria-controls="DataTables_Table_3" aria-disabled="true" role="link" data-dt-idx="previous" tabIndex={-1} className="page-link">Previous</a></li>
                                                        <li className="paginate_button page-item next disabled" id="DataTables_Table_3_next"><a aria-controls="DataTables_Table_3" aria-disabled="true" role="link" data-dt-idx="next" tabIndex={-1} className="page-link">Next</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </TabPanel>

                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default FeesHistory

