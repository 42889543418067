import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import OnlineExamData from '../../data/OnlineExamData';

const OnlineExam = () => {
    return (
        <>



            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className=' me-2 fa-solid fa-list' /> Online Exam List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Title</TableCell>
                                    <TableCell component={'th'} className=''>Class (Section)</TableCell>
                                    <TableCell component={'th'} className=''>Subject</TableCell>
                                    <TableCell component={'th'} className=''>Questions Qty</TableCell>
                                    <TableCell component={'th'} className=''>Start Time</TableCell>
                                    <TableCell component={'th'} className=''>End Time</TableCell>
                                    <TableCell component={'th'} className=''>Duration</TableCell>
                                    <TableCell component={'th'} className=''>Exam Fees</TableCell>
                                    <TableCell component={'th'} className=''>Exam Status</TableCell>
                                    <TableCell component={'th'} className=''>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {OnlineExamData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                            <TableCell component={'td'}>{item.ClassSection}</TableCell>
                                            <TableCell component={'td'}>{item.Subject}</TableCell>
                                            <TableCell component={'td'}>{item.QuestionsQty}</TableCell>
                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                            <TableCell component={'td'}>{item.EndTime}</TableCell>
                                            <TableCell component={'td'}>{item.Duration}</TableCell>
                                            <TableCell component={'td'}>{item.ExamFees}</TableCell>
                                            <TableCell component={'td'}><span class="badge bg-label-warning me-1">Live</span></TableCell>
                                            <TableCell component={'td'}>
                                                <button type="button" className="btn rounded-pill btn-outline-dark waves-effect fs-tiny p-1">
                                                    <span className=" fa-solid fa-users me-1" />Take Exam
                                                </button>

                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OnlineExam
