import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import '../../styles/homeStyle.css'


const HomeWork = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>

            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className='me-2 ti ti-folder' /> Homework List</h5>
                </div>

                <div className="card-body px-0">
                    <div class="accord-ques-div">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className='accordion-header text-body d-flex justify-content-between'
                            >
                                <Typography className="accord-ques accordion-button">
                                Chimistry - 24.Apr.2024
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className=''>
                                <div class="accordion-body">
                                    <div className=' d-flex justify-content-between'>
                                        <div className='mt-3'>
                                            <p className='p-4'>test home work 43254</p>
                                        </div>
                                        <div className="demo-inline-spacing mt-3">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Homework : 08.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Submission : 09.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Evaluation Date : 15.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-user ti-sm me-2" />
                                                    Created By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Status : <span className='ms-1 badge bg-label-success'>Complete</span>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Evaluated By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Rank Out Of 5 : 1
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Remarks : N/A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Subject : Einglish
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Class : Six
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Section : A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-clipboard-text ti-sm me-2" />
                                                    Documents : <button type="button" className="btn ms-1 rounded-pill btn-sm btn-icon btn-outline-primary waves-effect">
                                                        <span className="fa-solid fa-cloud-arrow-down fa-sm" />
                                                    </button>

                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                className='accordion-header text-body d-flex justify-content-between'
                            >
                                <Typography className="accord-ques accordion-button">
                                Mathematics - 24.Apr.2024
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className=''>
                                <div class="accordion-body">
                                    <div className=' d-flex justify-content-between'>
                                        <div className='mt-3'>
                                            <p className='p-4'>test home work 43254</p>
                                        </div>
                                        <div className="demo-inline-spacing mt-3">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Homework : 08.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Submission : 09.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Evaluation Date : 15.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-user ti-sm me-2" />
                                                    Created By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Status : <span className='ms-1 badge bg-label-success'>Complete</span>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Evaluated By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Rank Out Of 5 : 1
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Remarks : N/A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Subject : Einglish
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Class : Six
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Section : A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-clipboard-text ti-sm me-2" />
                                                    Documents : <button type="button" className="btn ms-1 rounded-pill btn-sm btn-icon btn-outline-primary waves-effect">
                                                        <span className="fa-solid fa-cloud-arrow-down fa-sm" />
                                                    </button>

                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                                className='accordion-header text-body d-flex justify-content-between'
                            >
                                <Typography className="accord-ques accordion-button">
                                Physics - 24.Apr.2024
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className=''>
                                <div class="accordion-body">
                                    <div className=' d-flex justify-content-between'>
                                        <div className='mt-3'>
                                            <p className='p-4'>test home work 43254</p>
                                        </div>
                                        <div className="demo-inline-spacing mt-3">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Homework : 08.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Submission : 09.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Evaluation Date : 15.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-user ti-sm me-2" />
                                                    Created By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Status : <span className='ms-1 badge bg-label-success'>Complete</span>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Evaluated By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Rank Out Of 5 : 1
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Remarks : N/A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Subject : Einglish
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Class : Six
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Section : A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-clipboard-text ti-sm me-2" />
                                                    Documents : <button type="button" className="btn ms-1 rounded-pill btn-sm btn-icon btn-outline-primary waves-effect">
                                                        <span className="fa-solid fa-cloud-arrow-down fa-sm" />
                                                    </button>

                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                                className='accordion-header text-body d-flex justify-content-between'
                            >
                                <Typography className="accord-ques accordion-button">
                                Biology - 24.Apr.2024
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className=''>
                                <div class="accordion-body">
                                    <div className=' d-flex justify-content-between'>
                                        <div className='mt-3'>
                                            <p className='p-4'>test home work 43254</p>
                                        </div>
                                        <div className="demo-inline-spacing mt-3">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Homework : 08.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Submission : 09.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Evaluation Date : 15.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-user ti-sm me-2" />
                                                    Created By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Status : <span className='ms-1 badge bg-label-success'>Complete</span>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Evaluated By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Rank Out Of 5 : 1
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Remarks : N/A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Subject : Einglish
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Class : Six
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Section : A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-clipboard-text ti-sm me-2" />
                                                    Documents : <button type="button" className="btn ms-1 rounded-pill btn-sm btn-icon btn-outline-primary waves-effect">
                                                        <span className="fa-solid fa-cloud-arrow-down fa-sm" />
                                                    </button>

                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5bh-content"
                                id="panel5bh-header"
                                className='accordion-header text-body d-flex justify-content-between'
                            >
                                <Typography className="accord-ques accordion-button">
                                    English - 14.Apr.2024
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className=''>
                                <div class="accordion-body">
                                    <div className=' d-flex justify-content-between'>
                                        <div className='mt-3'>
                                            <p className='p-4'>test home work 43254</p>
                                        </div>
                                        <div className="demo-inline-spacing mt-3">
                                            <ul className="list-group">
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Homework : 08.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Date Of Submission : 09.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-calendar ti-sm me-2" />
                                                    Evaluation Date : 15.Feb.2024
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-user ti-sm me-2" />
                                                    Created By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Status : <span className='ms-1 badge bg-label-success'>Complete</span>
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Evaluated By : Summer Simpson
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Rank Out Of 5 : 1
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Remarks : N/A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Subject : Einglish
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Class : Six
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-paper-bag ti-sm me-2" />
                                                    Section : A
                                                </li>
                                                <li className="list-group-item d-flex align-items-center">
                                                    <i className="ti ti-clipboard-text ti-sm me-2" />
                                                    Documents : <button type="button" className="btn ms-1 rounded-pill btn-sm btn-icon btn-outline-primary waves-effect">
                                                        <span className="fa-solid fa-cloud-arrow-down fa-sm" />
                                                    </button>

                                                </li>

                                            </ul>
                                        </div>


                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeWork
