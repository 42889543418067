import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import TeachersData from '../../data/TeachersData'

const Teachers = () => {

    
    return (
        <>

            <div class="card p-3">
                <div className='TeachersList'> 
                    <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-users' />Teachers List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                    <TableCell component={'th'} className=''>Name</TableCell>
                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                    <TableCell component={'th'} className=''>Department</TableCell>
                                    <TableCell component={'th'} className=''>Email</TableCell>
                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {TeachersData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>
                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                    <li
                                                        data-bs-toggle="tooltip"
                                                        data-popup="tooltip-custom"
                                                        data-bs-placement="top"
                                                        class="avatar avatar-md pull-up"
                                                        title="Lilian Fuller">
                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                    </li>
                                                </ul>
                                            </TableCell>
                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Teachers
