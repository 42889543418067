import img1 from '../assets/images/avatars/4.png'

const BookListData = [
    {
        Sl: 1,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 2,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 3,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 4,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 5,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 6,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 7,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 8,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 9,
        BookTitle: 'General Exam',
        Cover: img1,
        Edition: 'Mathematic',
        ISBNNo: '6',
        Category: '01.Jan.2024',
        Description: '31.Dec.2024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    }

];

export default BookListData;
