import React, { useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import myCalendarEvent from '../../data/CalendarEvent';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import '../../styles/dashboardPageStyle.css'
var CanvasJS = CanvasJSReact.CanvasJS;

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer


const data = [
    {
        XaxisName: "Jan",
        Remaining: 100,
        Collected: 200,
        Total: 100,
        ynumber: 100

    },
    {
        XaxisName: "Feb",
        Remaining: 200,
        Collected: 400,
        Total: 200,
        ynumber: 100

    },
    {
        XaxisName: "Mar",
        Remaining: 750,
        Collected: 900,
        Total: 450,
        ynumber: 100


    },
    {
        XaxisName: "Apr",
        Remaining: 250,
        Collected: 500,
        Total: 250,
        ynumber: 100
    },
    {
        XaxisName: "May",
        Remaining: 100,
        Collected: 200,
        Total: 100,
        ynumber: 200
    },
    {
        XaxisName: "Jun",
        Remaining: 250,
        Collected: 500,
        Total: 250,
        ynumber: 300
    },
    {
        XaxisName: "Jul",
        Remaining: 0,
        Collected: 0,
        Total: 0,
        ynumber: 400
    },
    {
        XaxisName: "Aug",
        Remaining: '',
        Collected: '',
        Total: '',
        ynumber: 500
    },
    {
        XaxisName: "Sep",
        Remaining: '',
        Collected: '',
        Total: '',
        ynumber: 600
    },
    {
        XaxisName: "Oct",
        Remaining: '',
        Collected: '',
        Total: '',
        ynumber: 700
    },
    {
        XaxisName: "Nov",
        Remaining: '',
        Collected: '',
        Total: '',
        ynumber: 800
    },
    {
        XaxisName: "Dec",
        Remaining: '',
        Collected: '',
        Total: '',
        ynumber: 900
    }
]

const moreData = [
    {
        XaxisName: "Jan",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 50

    },
    {
        XaxisName: "Feb",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 100

    },
    {
        XaxisName: "Mar",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 150


    },
    {
        XaxisName: "Apr",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 200
    },
    {
        XaxisName: "May",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 250
    },
    {
        XaxisName: "Jun",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 300
    },
    {
        XaxisName: "Jul",
        totalPresent: 0,
        totalAbsent: 0,
    tTotaLatel: 0,
        ynumber: 350
    },
    {
        XaxisName: "Aug",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 400
    },
    {
        XaxisName: "Sep",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 450
    },
    {
        XaxisName: "Oct",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 500
    },
    {
        XaxisName: "Nov",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 700
    },
    {
        XaxisName: "Dec",
        totalPresent: '',
        totalAbsent: '',
        totalLate: '',
        ynumber: 900
    }
]

function Dasboard() {

    const [moreOptions] = useState({
        theme: "light2",
        animationEnabled: true,
        exportEnabled: true,
        title: {
            text: "My Annual Attendance Overview"
        },
        axisY: {
            // title: "Number of iPhones ( in Million )"
        },
        data: [
            {
                type: "area",
                name: "Total Present",
                showInLegend: true,
                toolTip: {
                    shared: true
                },
                xValueFormatString: "MMM ",
                yValueFormatString: "#,##0.## Million",
                dataPoints: [
                    { label: 'Jan', y: '' },
                    { label: 'Feb', y: '' },
                    { label: 'Mar', y: '' },
                    { label: 'Apr', y: '' },
                    { label: 'May', y: '' },
                    { label: 'Jun', y: '' },
                    { label: 'Jul', y: '' },
                    { label: 'Aug', y: '' },
                    { label: 'Sep', y: '' },
                    { label: 'Oct', y: '' },
                    { label: 'Nov', y: '' },
                    { label: 'Dec', y: '' },

                ]
            },
            {
                type: "area",
                name: "Total Absent",
                showInLegend: true,
                toolTip: {
                    shared: true
                },
                xValueFormatString: "MMM ",
                yValueFormatString: "#,##0.## Million",
                dataPoints: [
                    { label: 'Jan', y: '' },
                    { label: 'Feb', y: '' },
                    { label: 'Mar', y: '' },
                    { label: 'Apr', y: '' },
                    { label: 'May', y: '' },
                    { label: 'Jun', y: '' },
                    { label: 'Jul', y: '' },
                    { label: 'Aug', y: '' },
                    { label: 'Sep', y: '' },
                    { label: 'Oct', y: '' },
                    { label: 'Nov', y: '' },
                    { label: 'Dec', y: '' },

                ]
            },
            {
                type: "area",
                name: "Total Late",
                showInLegend: true,
                toolTip: {
                    shared: true
                },
                xValueFormatString: "MMM ",
                yValueFormatString: "#,##0.## Million",
                dataPoints: [
                    { label: 'Jan', y: 0 },
                    { label: 'Feb', y: 0 },
                    { label: 'Mar', y: 0 },
                    { label: 'Apr', y: 0 },
                    { label: 'May', y: 0 },
                    { label: 'Jun', y: 0 },
                    { label: 'Jul', y: 0 },
                    { label: 'Aug', y: 0 },
                    { label: 'Sep', y: 0 },
                    { label: 'Oct', y: 0 },
                    { label: 'Nov', y: 0 },
                    { label: 'Dec', y: 0 },

                ]
            }
        ]
    });

    return (
        <>

            <div className="col-12 mb-4">
                <div className="card">
                    <h5 className=' card-header'>My Annual Fee Summary</h5>
                    <div className="card-body">
                        <AreaChart width={930} height={400} data={data}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#be853f" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#be853f" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#3f72be" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#3f72be" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#be3f7c" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#be3f7c" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis dataKey='ynumber' />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area type="monotone" dataKey="Remaining" stroke="#be853f" fillOpacity={1} fill="url(#colorUv)" />
                            <Area type="monotone" dataKey="Collected" stroke="#3f72be" fillOpacity={1} fill="url(#colorPv)" />
                            <Area type="monotone" dataKey="Total" stroke="#be3f7c" fillOpacity={1} fill="url(#colorTotal)" />
                        </AreaChart>
                    </div>
                </div>
            </div>


            <div className="row mb-4 g-4">
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="bottomborder d-flex align-items-center justify-content-between">
                                <div className="content-left">
                                    <span className="badge bg-label-primary rounded-circle p-2">
                                        <i className="ti ti-vocabulary ti-md" />
                                    </span>
                                    <br />
                                    <small>Book Issued</small>
                                </div>
                                <h4 className="mb-0">0</h4>
                            </div>
                            <div className='intervaldiv'>
                                INTERVAL 30 DAYS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="bottomborder d-flex align-items-center justify-content-between">
                                <div className="content-left">
                                    <span className="badge bg-label-primary rounded-circle p-2">
                                        <i className="ti ti-cloud-download ti-md" />
                                    </span>
                                    <br />
                                    <small>Attachments</small>
                                </div>
                                <h4 className="mb-0">0</h4>
                            </div>
                            <div className='intervaldiv'>
                                INTERVAL 30 DAYS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="bottomborder d-flex align-items-center justify-content-between">
                                <div className="content-left">
                                    <span className="badge bg-label-primary rounded-circle p-2">
                                        <i className="ti ti-credit-card ti-md" />
                                    </span>
                                    <br />
                                    <small>Fees Payment</small>
                                </div>
                                <h4 className="mb-0">0.00</h4>
                            </div>
                            <div className='intervaldiv'>
                                INTERVAL 30 DAYS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-xl-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="bottomborder d-flex align-items-center justify-content-between">
                                <div className="content-left">
                                    <span className="badge bg-label-primary rounded-circle p-2">
                                        <i className="ti ti-speakerphone ti-md" />
                                    </span>
                                    <br />
                                    <small>Events</small>
                                </div>
                                <h4 className="mb-0">0</h4>
                            </div>
                            <div className='intervaldiv'>
                                INTERVAL 30 DAYS
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12 mb-4">
                <div className="card">
                    <h5 className=' card-header'>My Annual Attendance Overview</h5>
                    <div className="card-body">
                        <AreaChart width={930} height={400} data={moreData}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#4ea769" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#4ea769" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#a74e79" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#a74e79" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#5b7dc2" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#5b7dc2" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis dataKey='ynumber' />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area type="monotone" dataKey="totalPresent" stroke="#4ea769" fillOpacity={1} fill="url(#colorUv)" />
                            <Area type="monotone" dataKey="totalAbsent" stroke="#a74e79" fillOpacity={1} fill="url(#colorPv)" />
                            <Area type="monotone" dataKey="totalLate" stroke="#5b7dc2" fillOpacity={1} fill="url(#colorTotal)" />
                        </AreaChart>
                    </div>
                </div>
            </div>

            <div className="card app-calendar-wrapper p-3">
                <div style={{ height: 600 }}>
                    <Calendar
                        localizer={localizer}
                        events={myCalendarEvent}
                        startAccessor="start"
                        endAccessor="end"
                    />
                </div>
            </div>



        </>
    )
}
export default Dasboard