import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'


const Attendance = () => {


    const defalutValue = {
        month: '',
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        month: yup.date().required('Month is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>



            <div class="card p-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"> Select Ground</h5>
                </div>


                <div className="card">
                    <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                        {({ errors, touched, resetForm }) => {
                            return <Form className="row">
                                <div className="card-body row g-3">
                                    <div className="col-md-6">
                                        <label className="form-label" htmlFor="">Month</label>

                                        <Field type='datetime-local' name='month' placeholder='Enter Product Name '
                                            className={
                                                `form-control
                                                     ${errors.month && touched.month ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p className='error_message_p'> <ErrorMessage name="month" /></p>
                                    </div>


                                    <div className="col-12 d-flex justify-content-end py-2">
                                        <button type="submit" name="submitButton" className="btn btn-primary">
                                            <span class="ti-xs ti ti-filter me-1"></span>Filter
                                        </button>
                                        <button type="button" className="btn btn-danger ms-2" onClick={resetForm}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        }}

                    </Formik>
                </div>
            </div>
        </>
    )
}

export default Attendance
