                                   
                                   
const ExamScheduleData = [
    {
        id: 1,
        ExamName: 'First Term Examination (Marks And Grade)',
        Action: ''

    },

    {
        id: 2,
        ExamName: 'First Term Examination (Marks And Grade)',
        Action: ''

    },
    {
        id: 3,
        ExamName: 'First Term Examination (Marks And Grade)',
        Action: ''

    },
    {
        id: 4,
        ExamName: 'First Term Examination (Marks And Grade)',
        Action: ''

    },
    {
        id: 5,
        ExamName: 'First Term Examination (Marks)',
        Action: ''

    },
    {
        id: 6,
        ExamName: 'First Term Examination (Marks)',
        Action: ''

    },
    {
        id: 7,
        ExamName: 'First Term Examination (Marks)',
        Action: ''

    },
    {
        id: 8,
        ExamName: 'First Term Examination (Marks)',
        Action: ''

    },
    {
        id: 9,
        ExamName: 'First Term Examination (Marks)',
        Action: ''

    }

];

export default ExamScheduleData;
