

export const FeesHistoryData = [
    {
        id: 1,
        FeesType: 'Einglish',
        DueDate: '20/40',
        Status: '',
        Amount: '50/100',
        Discount: 'fff',
        Fine: 'ssss',
        Paid: 'Paid',
        Balance: 'Balan',


    },
    {
        id: 2,
        FeesType: 'Einglish',
        DueDate: '20/40',
        Status: '',
        Amount: '50/100',
        Discount: 'fff',
        Fine: 'ssss',
        Paid: 'Paid',
        Balance: 'Balan',
    },
    {
        id: 3,
        FeesType: 'Einglish',
        DueDate: '20/40',
        Status: '',
        Amount: '50/100',
        Discount: 'fff',
        Fine: 'ssss',
        Paid: 'Paid',
        Balance: 'Balan',
    },
    {
        id: 4,
        FeesType: 'Einglish',
        DueDate: '20/40',
        Status: '',
        Amount: '50/100',
        Discount: 'fff',
        Fine: 'ssss',
        Paid: 'Paid',
        Balance: 'Balan',
    },
    {
        id: 5,
        FeesType: 'Einglish',
        DueDate: '20/40',
        Status: '',
        Amount: '50/100',
        Discount: 'fff',
        Fine: 'ssss',
        Paid: 'Paid',
        Balance: 'Balan',
    }

];