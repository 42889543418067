
const OnlineExamData = [
    {
        Sl: 1,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 2,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 3,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 4,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 5,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 6,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 7,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 8,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    },
    {
        Sl: 9,
        Title: 'General Exam',
        ClassSection: 'Six (A, B)',
        Subject: 'Mathematic',
        QuestionsQty: '6',
        StartTime: '01.Jan.2024',
        EndTime: '31.Dec.2024',
        Duration: '00:05:00',
        ExamFees: '$10',
    }

];

export default OnlineExamData;
