import { Button } from "@mui/material";
const BooksIssueData = [
    {
        id: 1,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Pending</Button>,
    },
    {
        id: 2,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Issued</Button>,
    },
    {
        id: 3,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Returned</Button>,
    },
    {
        id: 4,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Returned</Button>,
    },
    {
        id: 5,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Pending</Button>,
    },
    {
        id: 6,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Issued</Button>,
    },
    {
        id: 7,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Issued</Button>,
    },
    {
        id: 8,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Pending</Button>,
    },
    {
        id: 9,
        BookTitle: 'Cloud Computing',
        DateOfIssue: '02.Jan.2024',
        DateOfExpiry: '18.Jan.2024',
        Fine: '$0.00',
        Status: <Button className=" text-capitalize p-0" variant="outlined">Returned</Button>,
    }

];

export default BooksIssueData;
