import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import EventData from '../../data/EventData';

const Event = () => {
    return (
        <>



            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className=' me-2 <i fa-solid fa-list' /> Event List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''> #</TableCell>
                                    <TableCell component={'th'} className=''>Title</TableCell>
                                    <TableCell component={'th'} className=''>Type</TableCell>
                                    <TableCell component={'th'} className=''>Date Of Start</TableCell>
                                    <TableCell component={'th'} className=''>Date Of End</TableCell>
                                    <TableCell component={'th'} className=''>Audience</TableCell>
                                    <TableCell component={'th'} className=''>Created By</TableCell>
                                    <TableCell component={'th'} className=''>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {EventData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                            <TableCell component={'td'}>{item.Type}</TableCell>
                                            <TableCell component={'td'}>{item.DateOfStart}</TableCell>
                                            <TableCell component={'td'}>{item.DateOfEnd}</TableCell>
                                            <TableCell component={'td'}>{item.Audience}</TableCell>
                                            <TableCell component={'td'}>{item.CreatedBy}</TableCell>
                                            <TableCell component={'td'}><i class="ti ti-eye ti-sm text-black border border-2 rounded-circle p-1"></i></TableCell>

                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Event
