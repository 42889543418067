
import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import LiveClassData from '../../data/LiveClassData';

const LiveClass = () => {
    return (
        <>
            <div class="card p-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-headset' />Live Class List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Title</TableCell>
                                    <TableCell component={'th'} className=''>Meeting ID</TableCell>
                                    <TableCell component={'th'} className=''>Date</TableCell>
                                    <TableCell component={'th'} className=''>Start Time</TableCell>
                                    <TableCell component={'th'} className=''>End Time</TableCell>
                                    <TableCell component={'th'} className=''>Created By</TableCell>
                                    <TableCell component={'th'} className=''>Status</TableCell>
                                    <TableCell component={'th'} className=''>Created At</TableCell>
                                    <TableCell component={'th'} className=''>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {LiveClassData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                            <TableCell component={'td'}>{item.MeetingID}</TableCell>
                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                            <TableCell component={'td'}>{item.StartTime}</TableCell>
                                            <TableCell component={'td'}>{item.EndTime}</TableCell>
                                            <TableCell component={'td'}>{item.CreatedBy}</TableCell>
                                            <TableCell component={'td'}><span class="badge bg-label-danger me-1">Expired</span></TableCell>
                                            <TableCell component={'td'}>{item.CreatedAt}</TableCell>
                                            <TableCell component={'td'}>
                                                <button type="button" className="btn rounded-pill btn-outline-success waves-effect fs-tiny p-1">
                                                    <span className=" fa-solid fa-timeline me-1" />Join Class
                                                </button>

                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiveClass
