import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import AttachementData from '../../data/AttachementData';

const AttachmentsBook = () => {
    return (
        <>



            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-cloud-arrow-up' /> Attachments</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Title</TableCell>
                                    <TableCell component={'th'} className=''>Type</TableCell>
                                    <TableCell component={'th'} className=''>Class</TableCell>
                                    <TableCell component={'th'} className=''>Subject</TableCell>
                                    <TableCell component={'th'} className=''>Remarks</TableCell>
                                    <TableCell component={'th'} className=''>Publisher</TableCell>
                                    <TableCell component={'th'} className=''>Date</TableCell>
                                    <TableCell component={'th'} className=''>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {AttachementData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                            <TableCell component={'td'}>{item.Type}</TableCell>
                                            <TableCell component={'td'}>{item.Class}</TableCell>
                                            <TableCell component={'td'}>{item.Subject}</TableCell>
                                            <TableCell component={'td'}>{item.Remarks}</TableCell>
                                            <TableCell component={'td'}>{item.Publisher}</TableCell>
                                            <TableCell component={'td'}>{item.Date}</TableCell>
                                            <TableCell component={'td'}><i class="ti ti-cloud-download ti-sm text-black border border-2 rounded-circle p-1"></i></TableCell>

                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttachmentsBook
