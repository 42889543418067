import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { Link } from "react-router-dom"
import feesDataRows from '../../data/FeesData';
import BooksIssueData from '../../data/BooksIssueData';
import DocumentsData from '../../data/DocumentsData';
import ParentInformationData from '../../data/ParentInformationData';
import PromotionHistoryData from '../../data/PromotionHistoryData';
import { AcademicDetailData, StudentDetailData } from '../../data/ProfileDetailData';
import bgbannerImg from '../../assets/images/pages/profile-banner.png'
import studentprofile from '../../assets/images/avatars/studentprofile.png'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
const mangeTabActive = {
    color: 'white',
    // border: '1px solid #7367f0',
    backgroundColor: '#7367f0',
    borderRadius: '6px',
}

const parseCurrency = (currencyString) => {
    return parseFloat(currencyString.replace('$', '').replace(',', ''));
};
const Profile = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }

    const { amountSum, discountSum, fineSum, paidSum, balanceSum } = feesDataRows.reduce((acc, item) => {
        acc.amountSum += parseCurrency(item.Amount);
        acc.discountSum += parseCurrency(item.Discount);
        acc.fineSum += parseCurrency(item.Fine);
        acc.paidSum += parseCurrency(item.paid);
        acc.balanceSum += parseCurrency(item.Balance);
        return acc;
    }, {
        amountSum: 0,
        discountSum: 0,
        fineSum: 0,
        paidSum: 0,
        balanceSum: 0
    });


    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const defalutValue = {
        admissionDate: '',
        category: '',
        gender: '',
        fullName: '',
        motherTongue: '',
        religion: '',
        caste: '',
        phoneNo: '',
        city: '',
        state: '',
        schoolName: '',
        qualification: '',
        dateOfBirth: '',
        presentAddress: '',
        permanentAddress: '',
        remarks: ''

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        category: yup.string().required('Category is Requird!'),
        gender: yup.string().required('Gender is Requird!'),
        fullName: yup.string().required('Full Name is Requird!'),
        motherTongue: yup.string().required('Mother Tongue is Requird!'),
        religion: yup.string().required('Religion is Requird!'),
        caste: yup.string().required('Caste is Requird!'),
        phoneNo: yup.string().required('Phone No is Requird!'),
        city: yup.string().required('City is Requird!'),
        state: yup.string().required('State is Requird!'),
        schoolName: yup.string().required('School Name is Requird!'),
        qualification: yup.string().required('Qualification is Requird!'),
        admissionDate: yup.date().required('Admission Date is Requird!'),
        dateOfBirth: yup.date().required('Date Of Birth is Requird!'),
        presentAddress: yup.string().required('Present Address is Requird!'),
        permanentAddress: yup.string().required('Permanent Address is Requird!'),
        remarks: yup.string().required('Remarks is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }



    return (
        <>
            <div className="profile-head">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-4">
                            <div className="user-profile-header-banner">
                                <img src={bgbannerImg} alt="Banner image" className="rounded-top" />
                            </div>
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src={studentprofile} alt="user image" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                </div>
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4 className=' m-0'>Danielle Solomon</h4>
                                            <p>Student / General</p>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-3">
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-users fa-sm"></i> Binoya Naik
                                                </li>

                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-cake-candles fa-sm" />  29.Dec.2008
                                                </li>
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-school fa-sm"></i>  Six (A)
                                                </li>
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i className="fa-solid fa-phone-volume fa-sm" />   +17573352201
                                                </li>
                                                <li className="list-inline-item d-flex gap-2 align-items-center">
                                                    <i class="fa-solid fa-envelope fa-sm" />  student@ramom.com
                                                </li>
                                                <li className="list-inline-item d-flex gap-2 align-items-center"><i class="fa-solid fa-house fa-sm"></i> D/2684 Jefferson Street, Portsmouth, Virginia.</li>

                                            </ul>
                                        </div>
                                        {/* <a href="javascript:void(0)" className="btn btn-primary waves-effect waves-light">
                                            <i className="ti ti-check me-1" />Connected
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-user-circle' /> Profile Details</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-chart-line" /> Promotion History</Tab>
                            <Tab style={value === 2 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={2}><i className='manageicon ti ti-credit-card' /> Fees</Tab>
                            <Tab style={value === 3 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={3}><i class="manageicon fa-solid fa-users" /> Parent Information </Tab>
                            <Tab style={value === 4 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={4}><i class="manageicon fa-solid fa-book-open-reader" /> Book Issue</Tab>
                            <Tab style={value === 5 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={5}><i class="manageicon fa-solid fa-folder-open" /> Documents</Tab>
                            <Tab style={value === 6 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={6}><i className='manageicon fa-regular fa-pen-to-square' /> Edit Profile</Tab>

                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>
                            <div class="card p-3">
                                <h5 className="card-header d-flex align-items-center"><i className=' me-2 fa-solid fa-school' />  Academic Details</h5>
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-borderless">
                                            <TableBody component={'tbody'}>

                                                {AcademicDetailData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Branch</p>
                                                                    <h6>{item.Branch}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Academic Year</p>
                                                                    <h6>{item.AcademicYear}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Register No</p>
                                                                    <h6>{item.RegisterNo}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Roll</p>
                                                                    <h6>{item.Roll}</h6>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>

                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Admission Date</p>
                                                                    <h6>{item.AdmissionDate}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Class</p>
                                                                    <h6>{item.Class}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Section</p>
                                                                    <h6>{item.Section}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Category</p>
                                                                    <h6>{item.Category}</h6>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>

                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-user-check' />  Student Details</h5>
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-borderless">
                                            <TableBody component={'tbody'}>

                                                {StudentDetailData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>First Name</p>
                                                                    <h6>{item.FirstName}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Last Name</p>
                                                                    <h6>{item.LastName}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Gender</p>
                                                                    <h6>{item.Gender}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Blood group</p>
                                                                    <h6>{item.Bloodgroup}</h6>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>

                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Date Of Birth</p>
                                                                    <h6>{item.DateOfBirth}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Mother Tongue</p>
                                                                    <h6>{item.MotherTongue}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Religion</p>
                                                                    <h6>{item.Religion}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Caste</p>
                                                                    <h6>{item.Caste}</h6>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Mobile No</p>
                                                                    <h6>{item.MobileNo}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>Email</p>
                                                                    <h6>{item.Email}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>City</p>
                                                                    <h6>{item.City}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'}>
                                                                <div>
                                                                    <p>State</p>
                                                                    <h6>{item.State}</h6>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>

                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'} colSpan={2}>
                                                                <div>
                                                                    <p>Present Address</p>
                                                                    <h6>{item.PresentAddress}</h6>
                                                                </div>
                                                            </TableCell>
                                                            <TableCell component={'td'} colSpan={2}>
                                                                <div>
                                                                    <p>Permanent Address</p>
                                                                    <h6>{item.PermanentAddress}</h6>
                                                                </div>
                                                            </TableCell>

                                                        </TableRow>

                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                            <div class="card p-3">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>#</TableCell>
                                                    <TableCell component={'th'} className=''>From Class / Section</TableCell>
                                                    <TableCell component={'th'} className=''>From Session</TableCell>
                                                    <TableCell component={'th'} className=''>Promoted Class / Section</TableCell>
                                                    <TableCell component={'th'} className=''>Promoted Session</TableCell>
                                                    <TableCell component={'th'} className=''>Due Amount</TableCell>
                                                    <TableCell component={'th'} className=''>Promoted Date</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {PromotionHistoryData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.classname}</TableCell>
                                                            <TableCell component={'td'}>{item.fromsession}</TableCell>
                                                            <TableCell component={'td'}>{item.promotedclass}</TableCell>
                                                            <TableCell component={'td'}>{item.promotedsession}</TableCell>
                                                            <TableCell component={'td'}>{item.dueamount}</TableCell>
                                                            <TableCell component={'td'}>{item.promoteddate}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={2}>
                            <div class="card p-3">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} >#</TableCell>
                                                    <TableCell component={'th'} >Fees Type</TableCell>
                                                    <TableCell component={'th'} >Due Date</TableCell>
                                                    <TableCell component={'th'} >Status</TableCell>
                                                    <TableCell component={'th'} >Amount</TableCell>
                                                    <TableCell component={'th'} >Discount</TableCell>
                                                    <TableCell component={'th'} >Fine</TableCell>
                                                    <TableCell component={'th'} >Paid</TableCell>
                                                    <TableCell component={'th'} >Balance</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {feesDataRows.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.FeesType}</TableCell>
                                                            <TableCell component={'td'}>{item.DueDate}</TableCell>
                                                            <TableCell component={'td'}><span class="badge bg-label-success me-1">Paid</span></TableCell>
                                                            {/* <TableCell component={'td'}><span class="badge bg-label-danger me-1">Pending</span></TableCell> */}
                                                            <TableCell component={'td'}>{item.Amount}</TableCell>
                                                            <TableCell component={'td'}>{item.Discount}</TableCell>
                                                            <TableCell component={'td'}>{item.Fine}</TableCell>
                                                            <TableCell component={'td'}>{item.paid}</TableCell>
                                                            <TableCell component={'td'}>{item.Balance}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'td'} className=' fs-5'></TableCell>
                                                    <TableCell component={'td'} className=' fs-5'></TableCell>
                                                    <TableCell component={'td'} className=' fs-5'></TableCell>
                                                    <TableCell component={'td'} className=' fs-5'></TableCell>
                                                    <TableCell component={'td'} className=' fs-5'>${amountSum}</TableCell>
                                                    <TableCell component={'td'} className=' fs-5'>${discountSum}</TableCell>
                                                    <TableCell component={'td'} className=' fs-5'>${fineSum}</TableCell>
                                                    <TableCell component={'td'} className=' fs-5'>${paidSum}</TableCell>
                                                    <TableCell component={'td'} className=' fs-5'>${balanceSum}</TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={3}>
                            <div class="card p-3">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableBody component={'tbody'}>

                                                {ParentInformationData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Guardian Name</span> </TableCell>
                                                            <TableCell component={'td'}>{item.GuardianName}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">Relation </span></TableCell>
                                                            <TableCell component={'td'}>{item.Relation}</TableCell>
                                                        </TableRow>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Father Name</span> </TableCell>
                                                            <TableCell component={'td'}>{item.FatherName}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">Mother Name</span></TableCell>
                                                            <TableCell component={'td'}>{item.MotherName}</TableCell>
                                                        </TableRow>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Occupation</span> </TableCell>
                                                            <TableCell component={'td'}>{item.Occupation}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">Income</span></TableCell>
                                                            <TableCell component={'td'}>{item.Income}</TableCell>
                                                        </TableRow>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Education</span> </TableCell>
                                                            <TableCell component={'td'}>{item.Education}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">City</span></TableCell>
                                                            <TableCell component={'td'}>{item.City}</TableCell>
                                                        </TableRow>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">State</span> </TableCell>
                                                            <TableCell component={'td'}>{item.State}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">Mobile No</span></TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                        </TableRow>

                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Email</span> </TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}><span className="fw-medium">Address</span></TableCell>
                                                            <TableCell component={'td'}>{item.Address}</TableCell>
                                                        </TableRow>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}><span className="fw-medium">Guardian Picture</span> </TableCell>
                                                            <TableCell component={'td'}>{item.GuardianPicture}</TableCell>
                                                            <TableCell component={'td'}></TableCell>
                                                            <TableCell component={'td'}></TableCell>
                                                        </TableRow>

                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={4}>
                            <div class="card p-3">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} >#</TableCell>
                                                    <TableCell component={'th'} >Book Title</TableCell>
                                                    <TableCell component={'th'} >Date Of Issue</TableCell>
                                                    <TableCell component={'th'} >Date Of Expiry</TableCell>
                                                    <TableCell component={'th'} >Fine</TableCell>
                                                    <TableCell component={'th'} >Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {BooksIssueData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.BookTitle}</TableCell>
                                                            <TableCell component={'td'}>{item.DateOfIssue}</TableCell>
                                                            <TableCell component={'td'}>{item.DateOfExpiry}</TableCell>
                                                            <TableCell component={'td'}>{item.Fine}</TableCell>
                                                            <TableCell component={'td'}><span class="badge bg-label-warning me-1">Pending</span></TableCell>
                                                            {/* <TableCell component={'td'}><span class="badge bg-label-danger me-1">Returned</span></TableCell> */}
                                                            {/* <TableCell component={'td'}><span class="badge bg-label-success me-1">Issued</span></TableCell> */}
                                                        </TableRow>
                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={5}>
                            <div class="card p-3">
                                <div className="card-body p-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} >Sl</TableCell>
                                                    <TableCell component={'th'} >Title</TableCell>
                                                    <TableCell component={'th'} >Document Type</TableCell>
                                                    <TableCell component={'th'} >File</TableCell>
                                                    <TableCell component={'th'} >Remarks</TableCell>
                                                    <TableCell component={'th'} >Created At</TableCell>
                                                    <TableCell component={'th'} >Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {DocumentsData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.id}</TableCell>
                                                            <TableCell component={'td'}>{item.Title}</TableCell>
                                                            <TableCell component={'td'}>{item.DocumentType}</TableCell>
                                                            <TableCell component={'td'}>{item.File}</TableCell>
                                                            <TableCell component={'td'}>{item.Remarks}</TableCell>
                                                            <TableCell component={'td'}>{item.CreatedAt}</TableCell>
                                                            <TableCell component={'td'}><i class="ti ti-cloud-download ti-sm text-danger border border-2 rounded-circle p-1"></i></TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={6}>
                            <div className="card p-3">

                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body row g-3">
                                                {/* Account Details */}
                                                <div className="col-12">
                                                    <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-school' />  Academic Details</h5>
                                                    <hr className="mt-0" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationName">Admission Date</label>

                                                    <Field type='datetime-local' name='admissionDate' placeholder='Admission Date '
                                                        className={
                                                            `form-control
                                                             ${errors.admissionDate && touched.admissionDate ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="admissionDate" /></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationSelect2">Category</label>
                                                    <Field component='select' name='category' className={
                                                        `form-control
                                                            ${errors.category && touched.category ? ' is-invalid' : ''}`
                                                    }>
                                                        <option >--Select--</option>
                                                        <option value={71}>Airtel</option>
                                                        <option value={82}>BSNL</option>
                                                        <option value={83}>Vi</option>
                                                        <option value={94}>JIO</option>
                                                    </Field>

                                                    <p className='error_message_p'> <ErrorMessage name="category" /></p>
                                                </div>
                                                <div className="col-12">
                                                    <h5 className="d-flex align-items-center fs-5"><i className=' fs-4 me-2 fa-solid fa-user-check' /> Student  Details</h5>
                                                    <hr className="mt-0" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationEmail">Full Name</label>
                                                    <div className="input-group input-group-merge">
                                                        <span id="basic-icon-default-fullname2" className="input-group-text"><i className="ti ti-user" /></span>
                                                        <Field type='text' name='fullName' placeholder='Full Name '
                                                            className={
                                                                `form-control
                                                                     ${errors.fullName && touched.fullName ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                    </div>
                                                    <p className='error_message_p'> <ErrorMessage name="fullName" /></p>

                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationSelect2">Gender</label>
                                                    <Field component='select' name='gender' className={
                                                        `form-control
                                                            ${errors.gender && touched.gender ? ' is-invalid' : ''}`
                                                    }>
                                                        <option >--Select--</option>
                                                        <option value={71}>Airtel Prepaid</option>
                                                        <option value={82}>BSNL Prepaid</option>
                                                        <option value={83}>Vi Prepaid</option>
                                                        <option value={94}>JIO</option>
                                                    </Field>
                                                    <p className='error_message_p'> <ErrorMessage name="gender" /></p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationName">Date Of Birth</label>
                                                    <Field type='datetime-local' name='dateOfBirth' placeholder='Date Of Birth '
                                                        className={
                                                            `form-control
                                                            ${errors.dateOfBirth && touched.dateOfBirth ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="dateOfBirth" /></p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">Mother Tongue</label>
                                                    <Field type='text' name='motherTongue' placeholder='Mother Tongue '
                                                        className={
                                                            `form-control
                                                             ${errors.motherTongue && touched.motherTongue ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="motherTongue" /></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">Religion</label>
                                                    <Field type='text' name='religion' placeholder='Religion '
                                                        className={
                                                            `form-control
                                                             ${errors.religion && touched.religion ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="religion" /></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">Caste</label>
                                                    <Field type='text' name='caste' placeholder='Caste '
                                                        className={
                                                            `form-control
                                                             ${errors.caste && touched.caste ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="caste" /></p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationEmail">Phone No</label>
                                                    <div className="input-group input-group-merge">
                                                        <span id="basic-icon-default-phone2" class="input-group-text"><i class="ti ti-phone"></i></span>
                                                        <Field type='text' name='phoneNo' placeholder='Phone No '
                                                            className={
                                                                `form-control
                                                                     ${errors.phoneNo && touched.phoneNo ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                    </div>
                                                    <p className='error_message_p'> <ErrorMessage name="phoneNo" /></p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">City</label>
                                                    <Field type='text' name='city' placeholder='City '
                                                        className={
                                                            `form-control
                                                                     ${errors.city && touched.city ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="city" /></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">State</label>
                                                    <Field type='text' name='state' placeholder='State '
                                                        className={
                                                            `form-control
                                                                     ${errors.state && touched.state ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="state" /></p>
                                                </div>

                                                <div className="col-md-6"></div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationBio">Present Address</label>
                                                    <Field
                                                        as="textarea"
                                                        className={
                                                            `form-control
                                                                 ${errors.presentAddress && touched.presentAddress ? ' is-invalid' : ''}`
                                                        }
                                                        name="presentAddress" rows={3} defaultValue={""}
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="presentAddress" /></p>
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationBio">Permanent Address</label>
                                                    <Field
                                                        as="textarea"
                                                        className={
                                                            `form-control
                                                                 ${errors.permanentAddress && touched.permanentAddress ? ' is-invalid' : ''}`
                                                        }
                                                        name="permanentAddress" rows={3} defaultValue={""}
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="permanentAddress" /></p>
                                                </div>

                                                {/* Profile Picture  */}
                                                <div className="col-12">
                                                    <div className="card mb-4">
                                                        <h5 className="card-header">Profile Picture</h5>
                                                        <div className="card-body p-0">
                                                            <form onClick={handleFormClick} action="" className="dropzone needsclick dz-clickable" id="dropzone-basic">
                                                                <div className="dz-message needsclick">
                                                                    Drop files here or click to upload
                                                                    {/* <span className="note needsclick">(This is just a demo dropzone. Selected files are
                                                                    <span className="fw-medium">not</span> actually uploaded.)</span> */}
                                                                </div>
                                                            </form>
                                                            <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <h5 className="card-header d-flex align-items-center"><i className='me-2 fa-solid fa-bezier-curve' /> Previous School Details</h5>
                                                    <hr className="mt-0" />
                                                </div>

                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">School Name</label>
                                                    <Field type='text' name='schoolName' placeholder='School Name '
                                                        className={
                                                            `form-control
                                                                     ${errors.schoolName && touched.schoolName ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="schoolName" /></p>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" htmlFor="formValidationDob">Qualification</label>
                                                    <Field type='text' name='qualification' placeholder='Qualification '
                                                        className={
                                                            `form-control
                                                                     ${errors.qualification && touched.qualification ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="qualification" /></p>
                                                </div>

                                                <div className="col-12">
                                                    <label className="form-label" htmlFor="formValidationBio">Remarks</label>
                                                    <Field
                                                    as="textarea"
                                                        className={
                                                            `form-control
                                                                 ${errors.remarks && touched.remarks ? ' is-invalid' : ''}`
                                                        }
                                                        name="remarks" rows={3} defaultValue={""}
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="remarks" /></p>
                                                </div>

                                                <div className="col-12 d-flex justify-content-end py-2">
                                                    <button type="submit" name="submitButton" className="btn btn-primary">
                                                        <span class="ti-xs ti ti-plus me-1"></span>Updadte
                                                    </button>
                                                    <button type="button" className="btn btn-danger ms-2" onClick={resetForm}>
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    }}

                                </Formik>
                            </div>

                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default Profile
