const DocumentsData = [
    {
        id: 1,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 2,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 3,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 4,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 5,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 6,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 7,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 8,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    },
    {
        id: 9,
        Title: 'Birth',
        DocumentType: 'B certificate',
        File: 'Screenshot_20240122_205039_Google.jpg',
        Remarks: 'Recusandae Est obca',
        CreatedAt: '01.Jul.2023',
    }

];

export default DocumentsData;
