import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { GPA, marks, marksAndGrade, result } from '../../data/ReportCardData';

const ReportCard = () => {
    return (
        <>



            <div class="card px-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className=' me-2 fa-solid fa-id-card' /> Report Card</h5>
                </div>
                    <div className="card-body">
                        <div className="border border-1 rounded-end-top rounded-start-top TeachersList d-flex justify-content-between p-1 align-items-center">
                            <h5 className="card-header d-flex align-items-center px-1">First Term Examination (Marks)</h5>
                            <button type="button" class="btn btn-sm p-1 px-2 me-2 rounded-pill btn-outline-primary waves-effect">
                                <span class="ti-xs ti ti-printer me-1"></span>Print
                            </button>
                        </div>
                        <div className="table-responsive text-nowrap">
                            <Table className="table table-bordered">
                                <TableHead component={'thead'} className='table-light'>
                                    <TableRow component={'tr'}>
                                        <TableCell component={'th'} className=''>Subjects</TableCell>
                                        <TableCell component={'th'} className=''>Practical</TableCell>
                                        <TableCell component={'th'} className=''>Written</TableCell>
                                        <TableCell component={'th'} className=''>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={'tbody'}>

                                    {marks.map((item) => (
                                        <>
                                            <TableRow component={'tr'}>
                                                <TableCell component={'td'}>{item.Subjects}</TableCell>
                                                <TableCell component={'td'}>{item.Practical}</TableCell>
                                                <TableCell component={'td'}>{item.Written}</TableCell>
                                                <TableCell component={'td'}>{item.Total}</TableCell>
                                            </TableRow>
                                        </>
                                    ))}

                                    {result.map((item) => (
                                        <>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GRAND TOTAL</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.GRANDTOTAL}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Average</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.Average}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GRAND TOTAL IN WORDS</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.GRANDTOTALINWORDS}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GPA</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.GPA}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>RESULT</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.RESULT}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Position</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.Position}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Principal Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.PrincipalComments}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Teacher Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={3} component={'td'}>{item.TeacherComments}</TableCell>
                                            </TableRow>
                                           
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="border border-1 rounded-end-top rounded-start-top TeachersList d-flex justify-content-between p-1 align-items-center">
                            <h5 className="card-header d-flex align-items-center px-1">First Term Examination (Marks And Grade)</h5>
                            <button type="button" class="btn btn-sm p-1 px-2 me-2 rounded-pill btn-outline-primary waves-effect">
                                <span class="ti-xs ti ti-printer me-1"></span>Print
                            </button>
                        </div>
                        <div className="table-responsive text-nowrap">
                            <Table className="table table-bordered">
                                <TableHead component={'thead'} className='table-light'>
                                    <TableRow component={'tr'}>
                                        <TableCell component={'th'} className=''>Subjects</TableCell>
                                        <TableCell component={'th'} className=''>Practical</TableCell>
                                        <TableCell component={'th'} className=''>Attendance</TableCell>
                                        <TableCell component={'th'} className=''>Written</TableCell>
                                        <TableCell component={'th'} className=''>Viva</TableCell>
                                        <TableCell component={'th'} className=''>Total</TableCell>
                                        <TableCell component={'th'} className=''>Grade</TableCell>
                                        <TableCell component={'th'} className=''>Point</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={'tbody'}>

                                    {marksAndGrade.map((item) => (
                                        <>
                                            <TableRow component={'tr'}>
                                                <TableCell component={'td'}>{item.Subjects}</TableCell>
                                                <TableCell component={'td'}>{item.Practical}</TableCell>
                                                <TableCell component={'td'}>{item.Attendance}</TableCell>
                                                <TableCell component={'td'}>{item.Written}</TableCell>
                                                <TableCell component={'td'}>{item.Viva}</TableCell>
                                                <TableCell component={'td'}>{item.Total}</TableCell>
                                                <TableCell component={'td'}>{item.Grade}</TableCell>
                                                <TableCell component={'td'}>{item.Point}</TableCell>
                                            </TableRow>
                                        </>
                                    ))}

                                    {result.map((item) => (
                                        <>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GRAND TOTAL</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.GRANDTOTAL}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Average</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.Average}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GRAND TOTAL IN WORDS</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.GRANDTOTALINWORDS}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GPA</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.GPA}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>RESULT</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.RESULT}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Position</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.Position}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Principal Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.PrincipalComments}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Teacher Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.TeacherComments}</TableCell>
                                            </TableRow>
                                           
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="border border-1 rounded-end-top rounded-start-top TeachersList d-flex justify-content-between p-1 align-items-center">
                            <h5 className="card-header d-flex align-items-center px-1">First Term Examination (GPA)</h5>
                            <button type="button" class="btn btn-sm p-1 px-2 me-2 rounded-pill btn-outline-primary waves-effect">
                                <span class="ti-xs ti ti-printer me-1"></span>Print
                            </button>
                        </div>
                        <div className="table-responsive text-nowrap">
                            <Table className="table table-bordered">
                                <TableHead component={'thead'} className='table-light'>
                                    <TableRow component={'tr'}>
                                        <TableCell component={'th'} className=''>Subjects</TableCell>
                                        <TableCell component={'th'} className=''>Practical</TableCell>
                                        <TableCell component={'th'} className=''>Written</TableCell>
                                        <TableCell component={'th'} className=''>Grade</TableCell>
                                        <TableCell component={'th'} className=''>Point</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={'tbody'}>

                                    {GPA.map((item) => (
                                        <>
                                            <TableRow component={'tr'}>
                                                <TableCell component={'td'}>{item.Subjects}</TableCell>
                                                <TableCell component={'td'}>{item.Practical}</TableCell>
                                                <TableCell component={'td'}>{item.Written}</TableCell>
                                                <TableCell component={'td'}>{item.Grade}</TableCell>
                                                <TableCell component={'td'}>{item.Point}</TableCell>
                                            </TableRow>
                                        </>
                                    ))}

                                    {result.map((item) => (
                                        <>
                                           
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>GPA</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.GPA}</TableCell>
                                            </TableRow>
                                            
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Position</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.Position}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Principal Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.PrincipalComments}</TableCell>
                                            </TableRow>
                                            <TableRow component={'tr'}>
                                                <TableCell className='fw-bold' component={'td'}>Teacher Comments</TableCell>
                                                <TableCell className='fw-bold' colSpan={7} component={'td'}>{item.TeacherComments}</TableCell>
                                            </TableRow>
                                           
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default ReportCard
