// page
import './assets/css/pages/table.module.css'
import './assets/css/pages/advanced-wizard.css'
import './assets/css/pages/app-academy.css'
import './assets/css/pages/app-academy-details.css'
import './assets/css/pages/app-calendar.css'
import './assets/css/pages/app-chat.css'
import './assets/css/pages/app-ecommerce.css'
import './assets/css/pages/app-email.css'
import './assets/css/pages/app-invoice.css'
// import './assets/css/pages/app-invoice-print.css'
import './assets/css/pages/app-kanban.css'
import './assets/css/pages/app-logistics-dashboard.css'
import './assets/css/pages/app-logistics-fleet.css'
import './assets/css/pages/cards-advance.css'
import './assets/css/pages/front-page.css'
import './assets/css/pages/front-page-help-center.css'
import './assets/css/pages/front-page-landing.css'
import './assets/css/pages/front-page-payment.css'
import './assets/css/pages/front-page-pricing.css'
import './assets/css/pages/page-account-settings.css'
import './assets/css/pages/page-auth.css'
import './assets/css/pages/page-faq.css'
import './assets/css/pages/page-icons.css'
import './assets/css/pages/page-misc.css'
import './assets/css/pages/tabler.css'
import './assets/css/pages/page-pricing.css'
import './assets/css/pages/page-profile.css'
import './assets/css/pages/page-user-view.css'
import './assets/css/pages/ui-carousel.css'
import './assets/css/pages/wizard-ex-checkout.css'
import './assets/css/pages/datatables.bootstrap5.css'
import './assets/css/pages/demo.css'

// rtl 
import './assets/css/rtl/core.css'
import './assets/css/rtl/theme-default.css'
// import './assets/css/rtl/core-dark.css'
// import './assets/css/rtl/theme-bordered.css'
// import './assets/css/rtl/theme-bordered-dark.css'
// import './assets/css/rtl/theme-default-dark.css'
// import './assets/css/rtl/theme-raspberry-dark.css'
// import './assets/css/rtl/theme-semi-dark.css'
// import './assets/css/rtl/theme-semi-dark-dark.css'

// css
// import '../src/assets/css/core.css'
// import '../src/assets/css/core-dark.css'
// import '../src/assets/css/theme-bordered-dark.css'
// import '../src/assets/css/theme-default.css'
// import '../src/assets/css/theme-bordered.css'
// import '../src/assets/css/theme-raspberry-dark.css'
// import '../src/assets/css/theme-default-dark.css'
// import '../src/assets/css/theme-raspberry.css'
// import '../src/assets/css/theme-semi-dark-dark.css'
// import '../src/assets/css/theme-semi-dark.css'


// fonts
import '../src/assets/fonts/fontawesome/fa-brands-400.ttf'
import '../src/assets/fonts/fontawesome/fa-brands-400.woff2'
import '../src/assets/fonts/fontawesome/fa-regular-400.ttf'
import '../src/assets/fonts/fontawesome/fa-regular-400.woff2'
import '../src/assets/fonts/fontawesome/fa-solid-900.ttf'
import '../src/assets/fonts/fontawesome/fa-solid-900.woff2'
import '../src/assets/fonts/fontawesome/fa-v4compatibility.ttf'
import '../src/assets/fonts/fontawesome/fa-v4compatibility.woff2'
import '../src/assets/fonts/tabler/tabler-icons.eot'
import '../src/assets/fonts/tabler/tabler-icons.ttf'
import '../src/assets/fonts/tabler/tabler-icons.woff'
import '../src/assets/fonts/tabler/tabler-icons.woff2'
import '../src/assets/fonts/flag-icons.css'
import '../src/assets/fonts/fontawesome.css'
import '../src/assets/fonts/tabler-icons.css'

// vendor page
import './assets/vendor/css/pages/advanced-wizard.css'
import './assets/vendor/css/pages/app-academy-details.css'
import './assets/vendor/css/pages/app-academy.css'
import './assets/vendor/css/pages/app-calendar.css'
import './assets/vendor/css/pages/app-chat.css'
import './assets/vendor/css/pages/app-ecommerce.css'
import './assets/vendor/css/pages/app-email.css'
// import './assets/vendor/css/pages/app-invoice-print.css'
import './assets/vendor/css/pages/app-invoice.css'
import './assets/vendor/css/pages/app-kanban.css'
import './assets/vendor/css/pages/app-logistics-dashboard.css'
import './assets/vendor/css/pages/app-logistics-fleet.css'
import './assets/vendor/css/pages/cards-advance.css'
import './assets/vendor/css/pages/front-page-help-center.css'
import './assets/vendor/css/pages/front-page-landing.css'
import './assets/vendor/css/pages/front-page-payment.css'
import './assets/vendor/css/pages/front-page-pricing.css'
import './assets/vendor/css/pages/front-page.css'
import './assets/vendor/css/pages/page-account-settings.css'
import './assets/vendor/css/pages/page-auth.css'
import './assets/vendor/css/pages/page-faq.css'
import './assets/vendor/css/pages/page-icons.css'
import './assets/vendor/css/pages/page-misc.css'
import './assets/vendor/css/pages/page-pricing.css'
import './assets/vendor/css/pages/page-profile.css'
import './assets/vendor/css/pages/page-user-view.css'
import './assets/vendor/css/pages/ui-carousel.css'
import './assets/vendor/css/pages/wizard-ex-checkout.css'

// vendor rtl
// import './assets/vendor/css/rtl/core.css'
// import './assets/vendor/css/rtl/core-dark.css'
// import './assets/vendor/css/rtl/theme-bordered-dark.css'
// import './assets/vendor/css/rtl/theme-bordered.css'
// import './assets/vendor/css/rtl/theme-default-dark.css'
// import './assets/vendor/css/rtl/theme-default.css'
// import './assets/vendor/css/rtl/theme-raspberry-dark.css'
// import './assets/vendor/css/rtl/theme-raspberry.css'
// import './assets/vendor/css/rtl/theme-semi-dark-dark.css'
// import './assets/vendor/css/rtl/theme-semi-dark.css'

// vendor css
// import './assets/vendor/css/core.css'
// import './assets/vendor/css/core-dark.css'
// import './assets/vendor/css/theme-bordered-dark.css'
// import './assets/vendor/css/theme-bordered.css'
// import './assets/vendor/css/theme-default-dark.css'
// import './assets/vendor/css/theme-default.css'
// import './assets/vendor/css/theme-raspberry-dark.css'
// import './assets/vendor/css/theme-raspberry.css'
// import './assets/vendor/css/theme-semi-dark-dark.css'
// import './assets/vendor/css/theme-semi-dark.css'


// vendor libs
import './assets/vendor/libs/@form-validation/form-validation.css'
import './assets/vendor/libs/animate-css/animate.css'
import './assets/vendor/libs/animate-on-scroll/animate-on-scroll.css'
import './assets/vendor/libs/apex-charts/apex-charts.css'
import './assets/vendor/libs/bootstrap-datepicker/bootstrap-datepicker.css'
import './assets/vendor/libs/bootstrap-daterangepicker/bootstrap-daterangepicker.css'
import './assets/vendor/libs/bootstrap-maxlength/bootstrap-maxlength.css'
import './assets/vendor/libs/bootstrap-select/bootstrap-select.css'
import './assets/vendor/libs/bs-stepper/bs-stepper.css'
import './assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css'
import './assets/vendor/libs/datatables-buttons-bs5/buttons.bootstrap5.css'
import './assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css'
import './assets/vendor/libs/datatables-fixedcolumns-bs5/fixedcolumns.bootstrap5.css'
import './assets/vendor/libs/datatables-fixedheader-bs5/fixedheader.bootstrap5.css'
import './assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css'
import './assets/vendor/libs/datatables-rowgroup-bs5/rowgroup.bootstrap5.css'
import './assets/vendor/libs/datatables-select-bs5/select.bootstrap5.css'
import './assets/vendor/libs/dropzone/dropzone.css'
import './assets/vendor/libs/flatpickr/flatpickr.css'
import './assets/vendor/libs/fullcalendar/fullcalendar.css'
import './assets/vendor/libs/highlight/highlight-github.css'
import './assets/vendor/libs/highlight/highlight.css'
import './assets/vendor/libs/jkanban/jkanban.css'
import './assets/vendor/libs/jquery-timepicker/jquery-timepicker.css'
import './assets/vendor/libs/jstree/jstree.css'
import './assets/vendor/libs/jstree/themes/default/32px.png'
import './assets/vendor/libs/jstree/themes/default/40px.png'
import './assets/vendor/libs/jstree/themes/default/throbber.gif'
import './assets/vendor/libs/jstree/themes/default-dark/32px.png'
import './assets/vendor/libs/jstree/themes/default-dark/40px.png'
import './assets/vendor/libs/jstree/themes/default-dark/throbber.gif'
import './assets/vendor/libs/leaflet/leaflet.css'
import './assets/vendor/libs/leaflet/images/layers-2x.png'
import './assets/vendor/libs/leaflet/images/layers.png'
import './assets/vendor/libs/leaflet/images/marker-icon-2x.png'
import './assets/vendor/libs/leaflet/images/marker-icon.png'
import './assets/vendor/libs/leaflet/images/marker-shadow.png'

import './assets/vendor/libs/mapbox-gl/mapbox-gl.css'
import './assets/vendor/libs/node-waves/node-waves.css'
import './assets/vendor/libs/nouislider/nouislider.css'
import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import './assets/vendor/libs/pickr/pickr-themes.css'
import './assets/vendor/libs/plyr/plyr.css'
import './assets/vendor/libs/quill/editor.css'
import './assets/vendor/libs/quill/katex.css'
import './assets/vendor/libs/quill/typography.css'
import './assets/vendor/libs/quill/fonts/KaTeX_AMS-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_AMS-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_AMS-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Bold.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Bold.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Bold.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Caligraphic-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Bold.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Bold.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Bold.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Fraktur-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Bold.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Bold.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Bold.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-BoldItalic.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-BoldItalic.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-BoldItalic.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Italic.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-Italic.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-Italic.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Main-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-BoldItalic.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-BoldItalic.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-BoldItalic.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-Italic.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-Italic.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Math-Italic.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Bold.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Bold.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Bold.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Italic.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Italic.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Italic.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_SansSerif-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Script-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Script-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Script-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Size1-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Size1-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Size1-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Size2-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Size2-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Size2-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Size3-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Size3-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Size3-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Size4-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Size4-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Size4-Regular.woff2'
import './assets/vendor/libs/quill/fonts/KaTeX_Typewriter-Regular.ttf'
import './assets/vendor/libs/quill/fonts/KaTeX_Typewriter-Regular.woff'
import './assets/vendor/libs/quill/fonts/KaTeX_Typewriter-Regular.woff2'
import './assets/vendor/libs/rateyo/rateyo.css'
import './assets/vendor/libs/select2/select2.css'
import './assets/vendor/libs/shepherd/shepherd.css'
import './assets/vendor/libs/spinkit/spinkit.css'
import './assets/vendor/libs/sweetalert2/sweetalert2.css'
import './assets/vendor/libs/swiper/swiper.css'
import './assets/vendor/libs/tagify/tagify.css'
import './assets/vendor/libs/toastr/toastr.css'
import './assets/vendor/libs/typeahead-js/typeahead.css'



// import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/theme-raspberry.css'

import { Navigate, Route, Routes } from 'react-router-dom'
import AdminPage from './page/admin';
import DasboardPage from './page/dasboardPage';

import Profile from './page/profile/Profile.js'
import Teachers from './page/teachers/Teachers.js'
import AcademicSubject from './page/academic/AcademicSubject.js'
import AcademicClassSchedule from './page/academic/AcademicClassSchedule.js'
import LiveClass from './page/liveClass/LiveClass.js'
import LeaveApplication from './page/leaveApplication/LeaveApplication.js'
import AttachmentsBook from './page/attachmentsBook/AttachmentsBook.js'
import HomeWork from './page/homeWork/HomeWork.js'
import ExamSchedule from './page/examMaster/ExamSchedule.js'
import ReportCard from './page/examMaster/ReportCard.js'
import OnlineExam from './page/onlineExam/OnlineExam.js'
import SupervisionHotel from './page/supervision/SupervisionHotel.js'
import SupervisionTransport from './page/supervision/SupervisionTransport.js'
import Attendance from './page/attendance/Attendance.js'
import BooksList from './page/library/BooksList.js'
import BooksIssued from './page/library/BooksIssued.js'
import Event from './page/event/Event.js'
import FeesHistory from './page/feesHistory/FeesHistory.js'
import Message from './page/message/Message.js'
import ResetPassword from './page/resetPassword/ResetPassword.js'


function App() {
  return (
    <>
      <Routes>
        <Route
          path="/admin"
          element={<AdminPage />}
        >
          {/* bilal */}

          <Route path="" element={<DasboardPage />}></Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="teachers" element={<Teachers />}></Route>
          <Route path="academic_subject" element={<AcademicSubject />}></Route>
          <Route path="academic_class_schedule" element={<AcademicClassSchedule />}></Route>
          <Route path="live_class" element={<LiveClass />}></Route>
          <Route path="leave_application" element={<LeaveApplication />}></Route>
          <Route path="attachments_book" element={<AttachmentsBook />}></Route>
          <Route path="homework" element={<HomeWork />}></Route>
          <Route path="exam_schedule" element={<ExamSchedule />}></Route>
          <Route path="report_card" element={<ReportCard />}></Route>
          <Route path="online_exam" element={<OnlineExam />}></Route>
          <Route path="supervision_hotel" element={<SupervisionHotel />}></Route>
          <Route path="supervision_transport" element={<SupervisionTransport />}></Route>
          <Route path="attendance" element={<Attendance />}></Route>
          <Route path="books_list" element={<BooksList />}></Route>
          <Route path="books_issued" element={<BooksIssued />}></Route>
          <Route path="event" element={<Event />}></Route>
          <Route path="fees_history" element={<FeesHistory />}></Route>
          <Route path="message" element={<Message />}></Route>
          <Route path="reset_password" element={<ResetPassword />}></Route>

        </Route>
      </Routes>
    </>
  );
}

export default App;
