                                   
                                   
const AttachementData = [
    {
        Sl: 1,
        Title: 'ENGLISH GRAMMAR',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },

    {
        Sl: 2,
        Title: 'ENGLISH GRAMMAR',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 3,
        Title: 'ENGLISH GRAMMAR',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 4,
        Title: 'ENGLISH GRAMMAR',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 5,
        Title: 'ggggg',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 6,
        Title: 'ggggg',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 7,
        Title: 'ggggg',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 8,
        Title: 'ggggg',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 9,
        Title: 'ggggg',
        Type: 'physcs',
        Class: 'Six',
        Subject: 'English',
        Remarks: 'ghjg',
        Publisher: 'Nicholas Fletche',
        Date: '23.Mar.2024',
        Action: '+1 979-776-5395'

    }

];

export default AttachementData;
