import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import OnlineExamData from '../../data/OnlineExamData';
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'


const mangeTabActive = {
  color: '#7367f0',
  borderBottom: '2px solid #7367f0',
  // backgroundColor: '#7367f0',
  borderRadius: '0',
}

const BooksIssued = () => {

  const [value, setValue] = useState(0);

  const onHandleChange = (event, newValue) => {
    setValue(newValue)
  }

  const defalutValue = {
    bookTitle: '',
    dateOfIssue: '',
    dateOfExpiry: '',
  }

  const validationSceema = yup.object().shape({
    // fieldLbale: yup.string().required('SS').min(10).max(10),
    dateOfIssue: yup.date().required('Date Of Issue is Requird!'),
    dateOfExpiry: yup.date().required('Date Of Expiry is Requird!'),
    bookTitle: yup.string().required('BookTitle is Requird!'),

  })
  const handleSubmit = (value) => {
    console.log(value);
  }




  return (
    <>

      <div className="tabsBox">
        <Tabs value={value} onChange={onHandleChange} className="tabs">
          <div className="left-tabs">
            <TabsList className='mb-3'>
              <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-list' />  Book Issued</Tab>
              <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-edit" />  Book Request</Tab>
            </TabsList>
          </div>
          <div className="TabPanelDiv">
            <TabPanel value={0}>

              <div class="card p-3">
                <div className="exportFile">
                  <div className="fileDownload">
                    <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-copy" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-file-text" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-file-code-2" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="ti ti-printer" /></span>
                    </a>
                    <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                      <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                    </a>

                  </div>

                  <div>
                    <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                  </div>
                </div>

                <div className="card-body  px-0">
                  <div className="table-responsive text-nowrap">
                    <Table className="table table-bordered">
                      <TableHead component={'thead'} className='table-light'>
                        <TableRow component={'tr'}>
                          <TableCell component={'th'} className=''>Sl</TableCell>
                          <TableCell component={'th'} className=''>Book Title</TableCell>
                          <TableCell component={'th'} className=''>Author</TableCell>
                          <TableCell component={'th'} className=''>Publisher</TableCell>
                          <TableCell component={'th'} className=''>ISBN No</TableCell>
                          <TableCell component={'th'} className=''>Edition</TableCell>
                          <TableCell component={'th'} className=''>Date Of Issue</TableCell>
                          <TableCell component={'th'} className=''>Date Of Expiry</TableCell>
                          <TableCell component={'th'} className=''>Return Date</TableCell>
                          <TableCell component={'th'} className=''>Fine</TableCell>
                          <TableCell component={'th'} className=''>Status</TableCell>
                          <TableCell component={'th'} className=''>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody component={'tbody'}>

                        {OnlineExamData.map((item) => (
                          <>
                            <TableRow component={'tr'}>
                              <TableCell component={'td'}>{item.Sl}</TableCell>
                              <TableCell component={'td'}>{item.Title}</TableCell>
                              <TableCell component={'td'}>{item.ClassSection}</TableCell>
                              <TableCell component={'td'}>{item.Subject}</TableCell>
                              <TableCell component={'td'}>{item.QuestionsQty}</TableCell>
                              <TableCell component={'td'}>{item.StartTime}</TableCell>
                              <TableCell component={'td'}>{item.EndTime}</TableCell>
                              <TableCell component={'td'}>{item.Duration}</TableCell>
                              <TableCell component={'td'}>{item.ExamFees}</TableCell>
                              <TableCell component={'td'}>{item.ExamFees}</TableCell>
                              <TableCell component={'td'}><span class="badge bg-label-warning me-1">Pending</span></TableCell>
                              <TableCell component={'td'}>
                                <button type="button" className="btn rounded-pill btn-danger waves-effect fs-tiny p-1">
                                  <span className=" ti ti-trash me-1 ti-xs" />Delete
                                </button>

                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>

            </TabPanel>

            <TabPanel value={1}>
              <div className="card p-3">
                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                  {({ errors, touched, resetForm }) => {
                    return <Form className="row">
                      <div className="card-body">
                        <div id="formValidationExamples" className="row g-3 w-50 m-auto">

                          <div className="col-md-6 w-100">
                            <label className="form-label" htmlFor="formValidationSelect2">Book Title </label>
                            <Field component='select' name='bookTitle' className={
                              `form-control
                               ${errors.bookTitle && touched.bookTitle ? ' is-invalid' : ''}`
                            }>
                              <option >--Select--</option>
                              <option value={71}>Airtel Prepaid</option>
                              <option value={82}>BSNL Prepaid</option>
                              <option value={83}>Vi Prepaid</option>
                              <option value={94}>JIO</option>
                            </Field>
                            <p className='error_message_p'> <ErrorMessage name="bookTitle" /></p>
                          </div>

                          <div className="col-md-6 w-100">
                            <label className="form-label" htmlFor="formValidationName">Date Of Issue</label>
                            <Field type='datetime-local'
                              name='dateOfIssue' placeholder='Subject '
                              className={
                                `form-control
                                ${errors.dateOfIssue && touched.dateOfIssue ? ' is-invalid' : ''}`
                              }
                            />
                            <p className='error_message_p'> <ErrorMessage name="dateOfIssue" /></p>
                          </div>

                          <div className="col-md-6 w-100">
                            <label className="form-label" htmlFor="formValidationName">Date Of Expiry </label>
                            <Field type='datetime-local'
                              name='dateOfExpiry' placeholder='Subject '
                              className={
                                `form-control
                                ${errors.dateOfExpiry && touched.dateOfExpiry ? ' is-invalid' : ''}`
                              }
                            />
                            <p className='error_message_p'> <ErrorMessage name="dateOfExpiry" /></p>
                          </div>



                          <div className="col-12 d-flex justify-content-end py-2">
                            <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                              <span className="ti-xs ti ti-x me-1"></span>Discard
                            </button>

                            <button
                              type="submit" name="submitButton" className="btn btn-primary">
                              <span class="ti-xs ti ti-plus me-1"></span>Save
                            </button>

                          </div>
                        </div>
                      </div>
                    </Form>
                  }}
                </Formik>
              </div>

            </TabPanel>
          </div>
        </Tabs>
      </div>


    </>
  )
}

export default BooksIssued

