
import { Link } from 'react-router-dom'
import '../../styles/teacherStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import AcademicSubjectData from '../../data/AcademicSubjectData';

const AcademicSubject = () => {
    return (
        <>

            <div class="card p-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"><i className=' me-2 fa-solid fa-list-ul' /> Subject List</h5>
                </div>
                <div className="exportFile">
                    <div className="fileDownload">
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-copy" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-text" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-file-code-2" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="ti ti-printer" /></span>
                        </a>
                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                        </a>

                    </div>

                    <div>
                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                    </div>
                </div>

                <div className="card-body  px-0">
                    <div className="table-responsive text-nowrap">
                        <Table className="table table-bordered">
                            <TableHead component={'thead'} className='table-light'>
                                <TableRow component={'tr'}>
                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                    <TableCell component={'th'} className=''>Subject Name</TableCell>
                                    <TableCell component={'th'} className=''>Class Name</TableCell>
                                    <TableCell component={'th'} className=''>Class Teacher</TableCell>
                                    <TableCell component={'th'} className=''>Subject Code</TableCell>
                                    <TableCell component={'th'} className=''>Subject Type</TableCell>
                                    <TableCell component={'th'} className=''>Subject Author</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody component={'tbody'}>

                                {AcademicSubjectData.map((item) => (
                                    <>
                                        <TableRow component={'tr'}>
                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                            <TableCell component={'td'}>{item.SubjectName}</TableCell>
                                            <TableCell component={'td'}>{item.ClassName}</TableCell>
                                            <TableCell component={'td'}>{item.ClassTeacher}</TableCell>
                                            <TableCell component={'td'}>{item.SubjectCode}</TableCell>
                                            <TableCell component={'td'}>{item.SubjectType}</TableCell>
                                            <TableCell component={'td'}>{item.SubjectAuthor}</TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcademicSubject
